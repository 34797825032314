<script type="text/ecmascript-6">
export default {
    props: ['productConditions', 'productType', 'basketId']
}
</script>
<style scoped>
    .btn-outline-danger {
        background-color: white !important;
    }
    .btn-outline-danger:hover {
        background-color: #f34770 !important;
    }
</style>
<template>
    <div class="card-body pt-2">
        <hr class="card-separator"/>
        <div class="mt-3">
            <span type="button" @click="$router.push({name: 'basketInfo', params: {id: basketId}})" class="btn btn-outline-danger justify-content-center align-items-center d-flex border-lg" style="height: 50px;">Modifier mon déclaratif</span>
        </div>
    </div>
</template>