<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '../../components/basket/AcceptedOfferV2.vue'
import ProductCard from '../../components/basket/ProductCardV2.vue'
import ServicesCard from '../../components/basket/ServicesCard.vue'
import orderService from '@/services/order.js'
import ProductCaption from '../../components/basket/ProductCaption.vue'
import CancelOrderButton from '@/components/basket/CancelOrderButton.vue';
import ReinsuranceMessages from '../../components/basket/ReinsuranceMessages.vue'
import { uuid } from 'vue-uuid'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption,CancelOrderButton, ReinsuranceMessages}, 
    mixins: [orderMixin, merchantMixin],
    data() {

        return {

            previewImages: [],
            files:[],
            storageUsed: 0,
            maxNbrFiles: 1,
            modalCancelOrder: false,
            isLoading:false,
            error:null
        }
    },
    created() {
      
        this.$store.commit('setCurrentStep', 30)
    },
    methods: {

        addFile(e) {

            this.$tracking.logEvent('Click Add File', {'Order ID': this.orderId, 'Page Name': 'invoice'})

            let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files
            
            if (droppedFiles.length > this.maxNbrFiles) {
                
                alert('Vous ne pouvez pas importer plus de ' + this.maxNbrFiles + ' photo(s)');
                
                return;
            }

            var error
            var newDroppedFiles = []
            
            for (let i = 0; i < droppedFiles.length; i++) {
                const element = droppedFiles[i];

                if (element.size < 2050000) {

                    newDroppedFiles.push(element)
                    this.storageUsed += element.size
                }
                else {

                    error = 'Une ou plusieurs photos ont été retirées car elles dépassent les 2 Mo.'
                }
            }
            this.$emit('updateStorageData', this.storageUsed)
            droppedFiles = newDroppedFiles
            
            if (!droppedFiles) return
            ([...droppedFiles]).forEach(f => {
                
                let id = uuid.v4()
                this.files.push({id: id, file: f})
                this.addPreview(id, f)
                this.$emit('listingSteps')
            });

            if (error) {

                alert(error);
            }
        },

        removeFile(id) {

            this.files = this.files.filter(file => {
                
                this.storageUsed - file.size
                return file.id != id
            })
            this.previewImages = this.previewImages.filter(image => {

                return image.id != id
            })
        },
        addPreview(id, file) {

            let reader = new FileReader
            reader.onload = e => {

                let preview = e.target.result

                this.previewImages.push({id: id, preview})
            }

            return reader.readAsDataURL(file)
        },
        zoomPhoto(preview) {

            this.$emit("listingPhotoClicked", preview);
        },
        clickOnInput() {

            this.$refs.fileInput.click();
        },
        async cancelOrder() {

            try {
                
                this.$tracking.logEvent('Cancel Order Clicked', {'Order ID': this.orderId, 'Page Name': 'transfer'})

                let redirectionUrl = await orderService.cancelOrderById(this.orderId);
                window.location.href = redirectionUrl.data;
            }
            catch (error) {

                console.log(error);
            }
        },
        closeModalCancelOrder() {

            this.modalCancelOrder = false

            this.$tracking.logEvent('Cancel order closed', {'Order ID': this.orderId})
        },
        async uploadInvoice() {

            this.isLoading = true
            this.$tracking.logEvent('Click Upload Invoice', {'Order ID': this.orderId, 'Page Name': 'invoice'})

            try {

                this.error = null
                let response = await orderService.uploadInvoice(this.orderId, this.files[0].file)
    
                if (response.data) {
    
                    let query = null;
                    if (this.$route.query.f == 1) {
    
                        query = {f:1}
                    }
    
                    this.$router.push({name: 'orderTransfer', params: {id: this.order.public_id}, query})
    
                    this.isLoading = false
                }
            } 
            catch(error) {

                this.isLoading = false
                this.error = 'Un problème est survenu, veuillez contacter le service client'
            }
        }
    },
    computed: {

    },
    watch: {
        isOrderLoaded() {

            this.$tracking.pageView('invoice', 'order', {'Order ID': this.orderId, 'Coupon Type': this.order.coupon_type, 'Reseller Name': this.order.reseller.name, 'Category ID': this.order.category_id, 'Price': this.order.price})
        }
    }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}


.photo {

height: 200px;
width: 300px;
border: 1px solid var(--bs-gray-400);
background-size: contain;
background-repeat: no-repeat;
background-position: 50%;
position: relative;
}

.delete-btn {

    cursor: pointer;
    height: fit-content;
    width: fit-content;

}
</style>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <div>
                            <h3>Facture d'achat de votre produit</h3>
                            <span >
                                Merci de fournir une facture d'achat de votre  {{ order.label }}.
                            </span>
                            <input class="d-none" id="fileInput" ref="fileInput" :multiple="maxNbrFiles > 1" type="file" @change="addFile" accept="application/pdf,image/jpg,image/jpeg,image/png,image/gif">
                            <div class="d-flex align-items-center">
                                <button :disabled="files.length === maxNbrFiles" class="btn btn-info  me-4 my-3" @click="clickOnInput">
                                    <span>Chargez votre facture</span>
                                </button>
                            </div>
                            <div :key="preview.id" v-for="preview in previewImages" class="row p-2 d-flex flex-row">
                                
                                <object @click="zoomPhoto(preview.preview)" style="width:400px;height:300px" class="m-2 border col-12" :data="preview.preview"></object>
                                <span @click="removeFile(preview.id)" class="bg-white text-danger ms-1 px-2 delete-btn border col-1">X</span>
                            </div>
                            <button v-if="files.length === maxNbrFiles" class="btn btn-primary me-4 mt-3 mb-3" @click="uploadInvoice">
                                <span :disabled="isLoading">Confirmer l'envoi de votre facture  <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></span>
                            </button><br>
                            <span class="text-danger" v-if="error != null">{{ error }}</span>
                        </div>

                    </div>
                    <reinsurance-messages class="d-none d-md-block" v-if="isMerchantLoaded && Object.keys($t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.TRANSFER')).length > 0" :messages="$t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.TRANSFER')" />
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                        <cancel-order-button v-if="order.active_counter_offer === null && order.accepted_counter_offer === null" v-model="modalCancelOrder" @click="modalCancelOrder = true"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption v-if="merchant" :hasImmediat="merchant.can_have_immediat_coupon" />
                </div>
            </div>
        </div>
        <standard-modal v-if="modalCancelOrder">
            <template v-slot:body>
                <p> Êtes vous sûr de vouloir annuler votre reprise ?</p>
                <div class="d-flex justify-content-around">
                    <button role="button" class="btn btn-outline-danger" @click="cancelOrder()">Confirmer l'annulation</button>
                    <button role="button" class="btn btn-gd-dark"  @click="closeModalCancelOrder()"><span class="text-white">Retour</span></button>
                </div>
            </template>
        </standard-modal>
    </basket-layout>
</template>
