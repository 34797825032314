<script type="text/ecmascript-6">
export default {
    data() {

        return {

            steps : [
                {
                    step: 10,
                    number: 1,
                    name: 'Déclaratif'
                },
                {
                    step: 30,
                    number: 2,
                    name: 'Offre de reprise'
                },
                {
                    step: 40,
                    number: 3,
                    name: 'Confirmation'
                },
                {
                    step: 50,
                    number: 4,
                    name: 'Expédition gratuite'
                },
            ]
        } 
    },
    props: {
        currentStep: Number,
        merchant: Object
    },
    methods: {
        isCurrent(step) {

            if (step == this.currentStep || (this.currentStep == 10 && step == 20) || (this.currentStep == 20 && step == 10)) 
                return true

            return false    
        },
        isActive(step) {

            if (step <= this.currentStep)
                return true

            return false
        },
        updateStepName() {

            this.steps[3].name = this.merchant.can_accept_delivery_on_site ? 'Expédition gratuite ou dépôt en magasin' : 'Expédition gratuite'
        }
    },
    created() {

        this.updateStepName()
    }
}
</script>
<template>
    <div class="steps steps-light">
        <a class="step-item" v-for="step in steps" :key="step.step" :class="{active: isActive(step.step), current: isCurrent(step.step)}">
            <div class="step-progress" :class="{'current-step-progress': isCurrent(step.step)|| isActive(step.step)}"> 
            <span class="step-count" :class="{'current-step-progress': isCurrent(step.step)|| isActive(step.step)}">{{step.number}}</span>
            </div> 
            <div class="step-label" :class="{'text-muted': !isActive(step.step) && !isCurrent(step.step), 'text-success': isCurrent(step.step) ||!isCurrent(step.step)}">
                {{step.name}}
            </div>
        </a>
    </div>
</template>
