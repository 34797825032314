<script>
    export default {
        name: 'LoadingIcon'
    }
</script>

<template>
    <div class="loader"></div>
</template>

<style scoped>

.loader {
    border: 4px solid var(--iframe-border-grey-dark);
    border-top: 4px solid var(--iframe-color-brand);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>
  