export default {

    methods: {

        isFeatureActivated(featureName) {

            let key = 'VUE_APP_FEATURE_' + featureName
            let value = process.env[key]

            return value == 'true'
        }
    }
}