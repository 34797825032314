import axios from 'axios'

export default {

    postCardInfo(cardRegistrationURL, data, accessKeyRef, cardInfos) {

        const path = cardRegistrationURL
        let body = new URLSearchParams()
        
        body.append('data', data)
        body.append('accessKeyRef', accessKeyRef)
        body.append('cardNumber', cardInfos.cardNumber)
        body.append('cardExpirationDate', cardInfos.cardExpirationDate)
        body.append('cardCvx', cardInfos.cardCvx)
    
        return axios.post(path, body, {withCredentials: false})
    },
    createCardAccount(currency, cardType, mangoTermsAccepted) {

        const path = '/api/customer/cardRegistration'
        const data = {
            currency: currency,
            cardType: cardType,
            mangoTermsAccepted: mangoTermsAccepted
        }

        return axios.post(path, data)
    },
    createDepositPreAuth(orderPublicId, cardCurrency, cardId, version) {

        const path = '/api/customer/order/' + orderPublicId + '/depositPreAuth'
        const data = {
            javaEnabled: navigator.javaEnabled() ? 'true' : 'false',
            language: String(navigator.language),
            colorDepth: String(screen.colorDepth),
            screenHeight: String(screen.height),
            screenWidth: String(screen.width),
            timeZoneOffset: new Date().getTimezoneOffset().toString(),
            userAgent: navigator.userAgent,
            javascriptEnabled: true,
            cardId: cardId,
            cardCurrency: cardCurrency,
            version: version
        }

        return axios.post(path, data)
    },
    updateCardRegistration(cardRegistrationId, registrationData, orderPublicId) {

        const path = '/api/customer/cardRegistration/' + cardRegistrationId
        const data = {
            registrationData: registrationData,
            orderPublicId: orderPublicId,
            javaEnabled: navigator.javaEnabled() ? 'true' : 'false',
            language: String(navigator.language),
            colorDepth: String(screen.colorDepth),
            screenHeight: String(screen.height),
            screenWidth: String(screen.width),
            timeZoneOffset: new Date().getTimezoneOffset().toString(),
            userAgent: navigator.userAgent,
            javascriptEnabled: true
        }

        return axios.put(path, data)
    },
    createOrderReservationFromDeposit(orderPublicId, depositId) {

        const path = '/api/customer/order/' + orderPublicId + '/depositPreAuth/' + depositId + '/orderReservation'
        const data = {}

        return axios.post(path, data)
    }
}
