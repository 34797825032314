<script type="text/ecmascript-6">
import mediaMixin from '@/mixins/media'
export default {
    mixins: [mediaMixin],
    props: {
        'maxBestOffer': {
            'type': Object
        }, 
        'merchantData': {
            'type' : Object
        }, 
        'hasBonus': {
            'type': Boolean, 
            'default': false
        },
        'isSelected': {
            'type': Boolean, 
            'default': false
        }
    },
    methods: {

        vouchSelected() {

            if (this.$refs.inputNoCampaign != undefined) 
                this.$refs.inputNoCampaign.checked = true
            this.$emit('vouchSelected', { price: this.maxBestOffer.price, campaign: null, type: 'coupon' })
        }
    },
    created() {

        if (this.isSelected) {

            this.vouchSelected()
        }
    }
}
</script>

<template>
    <div class="d-flex flex-row mt-4 ms-3" @click="vouchSelected">
        <div class="card shadow-lg rounded-0 mx-0 col-md-7 col">
            <div class="row mx-0 h-100">
                <div class="col-1 px-0 row">
                    <div class="col-7 bg-success px-0"></div>
                    <div class="col-5 px-0"></div>
                </div>
                <div class="col-2 d-flex align-items-center px-2">
                    <input class="form-check-input" type="radio" id="no-campaign" ref="inputNoCampaign" name="campaign" :checked="isSelected">
                </div>
                <div class="card-body col-9">
                    <p class="mb-1">{{merchantData.coupon_name.toUpperCase()}} {{ merchantData.coupon_platform_name.toUpperCase() }}<img v-if="merchantData.id == 9884974566" width="40px" :src="getMerchantAsset(9884974566, 'pastille-carrefour.png')"/><br/><strong>TOUS PRODUITS</strong></p>
                    <h2 class="text-success d-flex align-items-center mt-2 mb-0">
                        <strong>{{maxBestOffer.price | currency}}*</strong>
                        <strong class="fs-xs ms-3" v-if="merchantData.can_have_immediat_coupon">utilisables dès maintenant (1)</strong>
                    </h2>
                    <strong v-if="maxBestOffer.price_raw != undefined" style="color:#1F56BB" class="fs-sm">dont {{ (maxBestOffer.price - maxBestOffer.price_raw) | currency }} de bonus {{ merchantData.coupon_platform_name }}</strong>
                    <p class="font-size-xxs mt-3 me-3 mb-0">Valable {{merchantData.coupon_validity_period}} {{merchantData.coupon_validity_products}} <span v-html="merchantData.coupon_validity_places"></span></p>
                </div>
            </div>
        </div>
    </div>
</template>