import SearchFormIframe from '../views/iframe/SearchForm.vue'
import BasketInfoIframe from '../views/iframe/Basket.vue'
import Error from '../views/iframe/Error.vue'
import FinalizeBasket from '../views/iframe/FinalizeBasket.vue'
import OrderCancelIframe from '../views/iframe/OrderCancel.vue'
import OrderFinalizeIframe from '../views/iframe/FinalizeOrder.vue'
import CardFormIframe from '../views/iframe/CardForm.vue'
import KycIframe from '../views/iframe/Kyc.vue'
import LoginIframe from '../views/iframe/Login.vue'
import TransferIframe from '../views/iframe/Transfer.vue'
import ValidatedIframe from '../views/iframe/Validated.vue'
import RequestAccess from '../views/iframe/RequestAccess.vue'
import IbanIframe from '../views/iframe/Iban.vue'
import InvoiceIframe from '../views/iframe/Invoice.vue'

export default [
    {
        path: '/iframe/searchForm',
        name: 'SearchFormIframe',
        component: SearchFormIframe
    },
    {
        path: '/iframe/basket/:id',
        name: 'basketInfoIframe',
        component: BasketInfoIframe
    },
    {
        path: '/iframe/error',
        name: 'error',
        component: Error
    },
    {
        path: '/iframe/basket/:id/finalizeBasket',
        name: 'finalizeBasket',
        component: FinalizeBasket
    },
    {
        path: '/iframe/order/:id/cancelOrder',
        name: 'orderCancelIframe',
        component: OrderCancelIframe
    },
    {
        path: '/iframe/basket/:id/finalizeOrder',
        name: 'orderFinalizeIframe',
        component: OrderFinalizeIframe
    },
    {
        path: '/iframe/order/:id/cardForm',
        name: 'cardFormIframe',
        component: CardFormIframe
    },
    {
        path: '/iframe/order/:id/kyc',
        name: 'kycIframe',
        component: KycIframe
    },
    {
        path: '/iframe/order/:id/login',
        name: 'loginIframe',
        component: LoginIframe
    },
    {
        path: '/iframe/order/:id/transfer',
        name: 'transferIframe',
        component: TransferIframe
    },
    {
        path: '/iframe/order/:id/validated',
        name: 'validatedIframe',
        component: ValidatedIframe
    },
    {
        path: '/iframe/order/:id/invoice',
        name: 'invoiceIframe',
        component: InvoiceIframe
    },
    {
        path: '/iframe/requestAccess',
        name: 'requestAccess',
        component: RequestAccess
    },
    {
        path: '/iframe/order/:id/iban',
        name: 'ibanIframe',
        component: IbanIframe
    },
]