import Merchant from '@/mixins/merchant.js'
export default {
    mixins: [Merchant],
    methods: {

        getFAQURL() {

            const subdomain = window.location.hostname.split('.')[0];
            const domain = window.location.hostname.split('.')[1];
            
            if (subdomain === 'fnac') {

                return 'https://fnac-greendid.crisp.help/fr/';
            }
            else if (subdomain === 'jerevends') {

                return 'https://jerevends-greendid.crisp.help/fr/';
            }
            else if (domain === 're-mind') {

                return 'https://re-mind-reprise.crisp.help/fr/'
            }
            else if (subdomain === 'carrefour') {

                return 'https://carrefour-web-greendid.crisp.help/fr/'
            }
            else if (domain === 'occasionleclercroques') {

                return 'https://reprise-occasion-leclerc-roques.crisp.help/fr/'
            }
            else if (domain.includes('bricomarche')) {

                return 'https://bricomarche-occasion.crisp.help/fr/'
            }
            else if (domain.includes('fnac')) {

                return 'https://fnac-reprise.crisp.help/fr/'
            }
            else if (domain.includes('darty')) {

                return 'https://darty-reprise.crisp.help/fr/'
            }

            return 'https://greendid-faq.crisp.help/fr/';
        },

        getFAQDeliveryURL() {

            const subdomain = window.location.hostname.split('.')[0];
            const domain = window.location.hostname.split('.')[1];

            if (subdomain === 'fnac') {
    
                return 'https://fnac-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-3ow3pp/';
            }
            else if (subdomain === 'jerevends') {

                return 'https://jerevends-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-186diz7/';
            }
            else if (domain === 're-mind') {

                return 'https://re-mind-reprise.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-1fzphry/'
            }
            else if (subdomain === 'carrefour') {

                return 'https://carrefour-web-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-7u1tiy/';
            }
            else if (domain === 'occasionleclercroques') {

                return 'https://reprise-occasion-leclerc-roques.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-fez7tx/'
            }
            else if (domain.includes('bricomarche')) {

                return 'https://bricomarche-occasion.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-d7fjbh'
            }
            else if (domain.includes('fnac')) {

                return 'https://fnac-reprise.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-kayh06/'
            }
            else if (domain.includes('darty')) {

                return 'https://darty-reprise.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-fq4v03/'
            }

            return 'https://greendid-faq.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-vxio8c/';
        },

        getFAQContactURL() {

            const subdomain = window.location.hostname.split('.')[0];
            const domain = window.location.hostname.split('.')[1];

            if (subdomain === 'fnac') {

                return 'https://fnac-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-1ocuz9v/';
            }
            else if (subdomain === 'jerevends') {

                return 'https://jerevends-greendid.crisp.help/fr/category/contacts-1sqcfo4/';
            }
            else if (domain === 're-mind') {

                return 'https://re-mind-reprise.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-102zy5d/'
            }
            else if (subdomain === 'carrefour') {

                return 'https://carrefour-web-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-1aoluev/';
            }
            else if (domain === 'occasionleclercroques') {

                return 'https://reprise-occasion-leclerc-roques.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-b4rgn8/'
            }
            else if (domain.includes('bricomarche')) {

                return 'https://bricomarche-occasion.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-6znph7'
            }
            else if (domain.includes('fnac')) {

                return 'https://fnac-reprise.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-z9mpmr/'
            }
            else if (domain.includes('darty')) {

                return 'https://darty-reprise.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-t0vafd/'
            }

            return 'https://greendid-faq.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-1hfutt3/';
        },
        getContactEmail() {

            const subdomain = window.location.hostname.split('.')[0];
            const domain = window.location.hostname.split('.')[1];
            
            if (subdomain == 'carrefour') {

                return 'contact-carrefour-web@greendid.com'
            }
            else if (domain === 're-mind') {

                return 'contact-reprise@re-mind.eco'
            }
            else if (domain.includes('bricomarche')) {

                return 'contact@occasion.bricomarche.com'
            }
            else if (domain.includes('fnac')) {

                return 'contat-fnac-reprise@greendid.com'
            }
            else if (domain.includes('darty')) {

                return 'contact-darty-reprise@greendid.com'
            }

            return this.merchant.white_label_domain ? 'contact-' + subdomain + '@' + this.merchant.white_label_domain : 'contact-' + subdomain + '@greendid.com'
        }
    }
}
