import axios from 'axios'

export default {

    listFacets(facets) {

        let path = '/api/customer/offerTemplates/facets?'

        if (facets != undefined) {

            let queryString = ''
            let facetNames = Object.keys(facets)
            facetNames.forEach(facetName => {
                
                if (facets[facetName] != null && facets[facetName] != '') {

                    if (queryString !== '') queryString += '&'
                    queryString += facetName + '=' + encodeURIComponent(facets[facetName])
                }
            });

            path = path + queryString
        }

        return axios.get(path)
    },

    generateDynamicFacets(filter, search) {

        let meilisearchPath = process.env.VUE_APP_MEILISEARCH_URL + '/indexes/offer_template/search'
        let limit = 1000

        let body = {
            "filter":filter,
            "q" : search,
            "attributesToSearchOn": ['displayed_brand', 'family', 'sub_family', 'model', 'option', 'capacity', 'label'],
            "limit": limit,
            "sort": ['label:asc']
        }

        let headers = {
            'Content-Type': 'application/json'
        }

        if (process.env.VUE_APP_MEILISEARCH_KEY) {

            headers['Authorization'] = 'Bearer ' + process.env.VUE_APP_MEILISEARCH_KEY
        }

        return axios.post(meilisearchPath, body, {
            withCredentials: false,
            headers: headers
        })
    }
}
