<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import mediaMixin from '@/mixins/media'
import DropFile from '../../components/iframe/DropFile.vue';
import kycService from '../../services/kyc'

export default {

    components: {BasketLayout, SidePanelHeader, Btn, DropFile}, 
    mixins: [merchantMixin, orderMixin, mediaMixin],
    data() {
        return {

                kycTypes:[
                    {name:"Carte d'identité (Recto et Verso en 1 ou 2 fichiers)", value: "identity_card"},
                    {name:"Passeport (Page avec photo)", value: "passport"},
                    {name:"Permis de séjour (Recto et Verso en 1 ou 2 fichiers) ",value: "resident_permit"},
                    {name:"Permis de conduire",value: "driver_licence"},
                ],
                identityType: null,
                files:[],
                isInputFocused:false,
                
            }

        },
    methods: {
        async upload() {

            this.error = ''
            this.isLoading = true

            if (this.files.length > 2) {
                
                this.error = 'Vous ne pouvez pas importer plus de 2 photos'
                this.isLoading = false

                return;
            }
            else {

                let file1 = this.files[0]
                let file2 = null
                if (this.files.length > 1) 
                    file2 = this.files[1]

                this.isUploadLoading = true
                try {

                    await kycService.uploadKyc(this.identityType, file1, file2)
                }
                catch (error) {

                    console.log('KYC upload error', {'Error': error});
                }
                this.isUploadLoading = false

                this.$router.push({name: 'transferIframe', params: {id: this.order.public_id}})
            }

            this.isLoading = false
        },
        onInputFocus() {

            this.isInputFocused = true;
        },
        onInputBlur() {

            this.isInputFocused = false;
        },
        focusInput(inputName) {
            this.$refs[inputName].focus();
        },
    },
    computed: {
       
        canValidate() {
            
            return this.identityType != null && this.files.length > 0
        }
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('Kyc', 'Iframe Order', {'Order ID': this.order.public_id})
        }
    }
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="'Vérification d\'identité'"/>
        <div v-if="isOrderLoaded" class="iframe-content">
            <h3 class="kyc-content-title">Téléchargez votre pièce d’identité</h3>
            <div class="custom-select">
                <select v-model="identityType" class="select-box">
                    <option value="" disabled selected hidden></option>
                    <option  v-bind:key="index" :value="type.value" v-for="(type, index) in kycTypes" aria-placeholder="Type de pièce d'identité">
                        {{ type.name }}
                    </option>
                </select>
                <span class="arrow-down"></span>
                <span v-if="!identityType" class="placeholder">
                    <span class="placeholder-text">Type de pièce d'identité</span>
                </span>
            </div>
            <div id="import-files-wrapper">
                <drop-file @updateFiles="files = $event" />
            </div>
            <p class="mt-3">
                La collecte de votre pièce d'identité est nécessaire dans le cadre de la tenue d'un livre de police par Greendid en tant que responsable de traitement conformément à l'article 321-7 code pénal. Pour plus d'informations, vous pouvez consulter la <a :href="getPrivacyPolicyLink(subdomain)" style="text-decoration:underline" target="_blank">Politique De Données Personnelles</a>. <img src="/assets/img/external-link.svg" />. 
            </p>
            <div @click="upload()" id="btn-validate-container">
                <btn class="btn-iframe-dark" :disabled="!canValidate" :text="'Continuer'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" /> 
            </div>
        </div>
    </basket-layout>
</template>
<style scoped>
.custom-select {
    position: relative;
    display: flex;
    align-items: center;
   
}

.custom-select select {

    width: 100%; 
    padding: 10px;
    font-size: 14px;
    border: 1px solid var(--iframe-border-grey-normal);
    border-radius: 4px; 
    color: var(--iframe-color-black);
    appearance: none;
    cursor: pointer;
}

.custom-select .arrow-down {
    
    width: 12px;
    height: 12px;
    z-index: 10;
    position: absolute;
    transform: rotate(45deg);
    display: inline-block;
    border-bottom: 2px var(--iframe-color-black) solid;
    border-right: 2px var(--iframe-color-black) solid;
}

.custom-select .arrow-down{
    
    top:26px;
    right:20px;
}

.select-box {
    height: 40px;
    width: 100%;
    margin: 16px 0 24px 0;
    border-radius: 4px;
}

.placeholder {
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    left: 8px;
    color: white;
    pointer-events: none;
}

.placeholder-text {
    color: var(--iframe-color-black);
    font-size: var(--iframe-font-size-p);
}

#btn-validate-container {
    margin:20px 0px 20px 0px;
}

</style>

