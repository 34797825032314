import axios from 'axios'

export default {

    list() {

        var path = '/api/customer/ordersHistory'

        return axios.get(path)
    },
    delete(orderHistoryId) {

        var path = '/api/customer/ordersHistory/' + orderHistoryId

        return axios.delete(path)
    }
}
