"use strict";

import axios from "axios";

// Full config:  https://github.com/axios/axios#request-config=

const domain = window.location.hostname.substring(
    window.location.hostname.indexOf(".") + 1, 
    window.location.hostname.lastIndexOf(".")
)

const subdomain = window.location.hostname.split('.')[0]

if (domain === 're-mind') {

    axios.defaults.baseURL = process.env.VUE_APP_API_URL_REMIND;
}
else if (domain.includes('leclercroques') || subdomain == 'leclercroques') {

    axios.defaults.baseURL = process.env.VUE_APP_API_URL_LECLERC_ROQUES;
}
else if (domain.includes('fnac') || domain.includes('fd-recette')) {

    axios.defaults.baseURL = process.env.VUE_APP_API_URL_FNAC_REPRISE;
}
else if (domain.includes('bricomarche') || subdomain == 'bricomarche') {

    axios.defaults.baseURL = process.env.VUE_APP_API_URL_BRICOMARCHE;
}
else if (domain.includes('darty')) {

    axios.defaults.baseURL = process.env.VUE_APP_API_URL_DARTY_REPRISE;
}
else {

    axios.defaults.baseURL = process.env.VUE_APP_API_URL;
}

//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
