<script type="text/ecmascript-6">
export default {
    props: {
        'price': {
            'required': true
        },
        'isSelected': {
            'type': Boolean, 
            'default': false
        },
        'bonuses': {
            'type': Array,
            'default': []
        }
    },
    methods: {
        selectVouch(campaign) {

            if (this.$refs.inputCash)
                this.$refs.inputCash.checked = true
            
            this.$emit('vouchSelected', { price: this.price, campaign: campaign, type: 'cash' })
        },
        getBonusAmount(bonus) {

            let value = bonus.bonus_amount / 100
            if (bonus.bonus_type == 'percent') {

                return value + ' %'
            }

            return '+' + value + ' €'
        },
        getBgColor(bonus) {

            return 'bg-' + bonus.color
        },
        getTextColor(bonus) {

            return 'text-' + bonus.color
        },
    },
    created() {

        if (this.isSelected) {

            this.selectVouch(null);
        }
    },
    computed: {
        cashBonuses() {

            return this.bonuses.filter(bonus => bonus.is_cash_refund)
        }
    }
}
</script>
<template>
    <section>
        <div class="d-flex flex-row mt-4 ms-3" @click="selectVouch(null)">
            <div class="card shadow-lg rounded-0 mx-0 col-md-7 col">
                <div class="row mx-0 h-100">
                    <div class="col-1 px-0 row">
                        <div class="bg-info col-7 px-0"></div>
                        <div class="col-5 px-0"></div>
                    </div>
                    <div class="col-2 d-flex justify-content-center align-items-center px-0">
                        <input class="form-check-input" type="radio" id="cash" ref="inputCash" name="campaign" :checked="isSelected">
                    </div>
                    <div class="card-body col-9">
                        <p class="mb-1">EN CASH<br/><strong>PAR VIREMENT</strong></p>
                        <h2 class="text-info"><strong>{{price | currency}}</strong></h2>
                        <p class="font-size-xxs mt-3 me-3 mb-0">
                            Sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis. Argent disponible sous 14 jours après validation.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="bonus in cashBonuses" :key="bonus.public_id" class="d-flex flex-row mt-4 ms-3" @click="selectVouch(bonus)">
            <div class="card shadow-lg rounded-0 mx-0 col-md-7 col">
                <div class="row mx-0 h-100">
                    <div class="col-1 px-0 row">
                        <div :class="getBgColor(bonus)" class="col-7 px-0"></div>
                        <div class="col-5 px-0"></div>
                    </div>
                    <div class="col-2 d-flex justify-content-center align-items-center px-0">
                        <input class="form-check-input" type="radio" id="cash" ref="inputCash" name="campaign">
                    </div>
                    <div class="card-body col-9">
                        <p class="mb-1">EN CASH<br/><strong>PAR VIREMENT</strong></p>
                        <h2 :class="getTextColor(bonus)"><strong>{{price | currency}}</strong></h2>
                        <p class="font-size-xxs mt-3 me-3 mb-0">
                            Sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis. Argent disponible sous 14 jours après validation.
                        </p>
                    </div>
                </div>
            </div>
            <div class="card shadow-lg rounded-0 col-5 px-0 vouch-bonus-separator">
                <div class="d-flex flex-column mx-2 p-3">
                    <p class="mb-auto">BONUS<br/><strong>{{ bonus.name }}</strong></p>
                    <h2 :class="getTextColor(bonus)"><strong>{{ getBonusAmount(bonus)}}</strong></h2>
                    <p class="font-size-xxs mt-3 me-3 mb-0" v-html="bonus.legal_application_label"></p>
                </div>
            </div>
        </div>
    </section>
</template>