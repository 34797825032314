
export default {

    computed: {
        isSkuLoaded() {

            return this.$store.getters.skus != null
        },
    }
}
