import axios from 'axios'

export default {

    get() {

        var path = '/api/customer/settings'

        return axios.get(path)
    },
    update(phoneNumber) {

        var path = '/api/customer/settings'
        var data = {
            'phoneNumber': phoneNumber
        }

        return axios.patch(path, data)
    }
}
