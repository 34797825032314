<script type="text/ecmascript-6">

import authService from '@/services/auth.js'
import featureMixin from '@/mixins/feature.js'

export default {

    mixins: [featureMixin],
    props: {
        'basketPublicId': {
            type: String,
            default: null
        }, 
        'listingPublicId': {
            type: String,
            default: null
        }, 
        'merchantId': {
            default: null
        }
    },
    data() {

        return {

            isLoading:false
        }
    },
    methods: {

        async submitSSOAuth(ssoType) {
                
            let amplitudeParams = null

            if (this.basketPublicId != null) {

                amplitudeParams = {'Basket ID':this.basketPublicId}
            }

            if (this.listingPublicId != null) {

                amplitudeParams = {'Listing ID':this.listingPublicId}
            }

            if (this.merchantId != null) {

                amplitudeParams = {'Merchant ID':this.merchantId}
            }

            this.$tracking.logEvent('Sso Google Button Clicked', amplitudeParams)

            this.isLoading = true
            let response = await authService.ssoAuth(this.basketPublicId, this.listingPublicId, window.location.href, this.merchantId, ssoType)
            
            if (response.data) {

                window.location.href = response.data
            }
        },
    },
    computed: {

        displaySSOGoogle() {

            return this.isFeatureActivated('SSO_GOOGLE')
        }
    }
}
</script>

<template>
    <section>
        <button v-if="displaySSOGoogle" :disabled="isLoading" class="btn btn-outline-dark text-center w-100" @click="submitSSOAuth('google')">
            <span class="mr-5">Je m'identifie avec Google</span>
            <i v-if="!isLoading" class="bi bi-google ms-1"></i>
            <span v-if="isLoading" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
        </button>
    </section>
</template>