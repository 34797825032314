<script type="text/ecmascript-6">
export default {

    created() {

        document.body.style = 'background-color:white;overflow: hidden'
    }
}
</script>
<template>
    <div id="greendid-side-panel-iframe">
        <slot/>
    </div>
</template>
<style>
.iframe-content {
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding:0 32px 32px 32px;
}

#greendid-side-panel-iframe {
    padding: 2px 2px 2px 2px;
    height: 100vh;
    overflow: hidden;
}
</style>