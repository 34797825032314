<script type="text/ecmascript-6">

import userService from '@/services/user'

export default {

    props: {
        'isLoading': {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

            address: {
                address1: null,
                address2: null,
                city: null,
                zipcode: null,
                country: 'France'
            }
        }
    },
    methods: {
        async createAddress() {

            let errorMessage = ''

            try {

                await userService.postAddress(this.address)
            }
            catch (error) {

                this.$tracking.logEvent('Address Error')

                if (error.response.data && error.response.data.errors) {

                    Object.keys(error.response.data.errors).forEach(key => {
                        
                        const fields = {
                            address1: 'Adresse',
                            city: 'Ville',
                            zipcode: 'Code Postal',
                            country: 'Pays'
                        }

                        errorMessage += 'Le champ ' + fields[key] + ' n\'est pas valide.<br/>'
                    });

                    return errorMessage
                }

                return 'Une erreur est intervenue.'
            }

            return true
        }
    }
}
</script>
<template>
    <div class="mb-2 mt-2">
        <div><strong>Votre adresse postale</strong></div>
        <div class="form-group mt-3 d-flex">
            <input class="form-control me-3" :disabled="isLoading" v-model="address.address1" type="text" placeholder="Adresse ligne 1">
            <input class="form-control me-3" :disabled="isLoading" v-model="address.address2" type="text" placeholder="Adresse ligne 2">
        </div>
        <div class="form-group mt-3 d-flex">
            <input class="form-control me-3" :disabled="isLoading" v-model="address.zipcode" type="text" placeholder="Code postal">
            <input class="form-control me-3" :disabled="isLoading" v-model="address.city" type="text" placeholder="Ville">
            <input class="form-control me-3" disabled v-model="address.country" type="text">
        </div>
    </div>
</template>