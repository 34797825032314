<script type="text/ecmascript-6">
import offerTemplateService from '@/services/offerTemplate.js'
import merchantMixin from '@/mixins/merchant.js'

export default {
    props: ['categoryId'],
    mixins: [merchantMixin],
    data() {

        return {

            searchInput: '',
            items: null
        }
    },
    methods: {

        itemSelected(val) {

            this.searchInput = val.label
            this.items = null
            this.$emit('itemSelected', val)
        },
        resetSearchInput() {
            this.searchInput = ''
            this.items = null
        },
        async loadDynamicFacets() {

            //if (this.searchInput.length >= 4) {
                
                let filter = `category_id = ${this.categoryId}`
                let response = await offerTemplateService.generateDynamicFacets(filter, this.searchInput)
    
                this.items = response.data.hits
            //}
            
            this.$emit('searchInputChanged', this.searchInput)
        },
    }
}
</script>
<template>
    <div :class="merchantCss + '-smartphone-div'">
        <span :class="'questions-title'">Quel smartphone souhaitez vous revendre ?</span>
        <input style="margin-bottom: 0;padding:10px" type="text" @input="loadDynamicFacets" @change="loadDynamicFacets" v-model="searchInput" name="searchInput" id="searchInput" :class="'select-box'" list="searchInput" placeholder="Tapez le nom de votre smartphone">
        <div v-if="items" :class="merchantCss + '-input-result-box'">
            <div :class="merchantCss + '-input-results'" :value="val" v-for="val in items" :key="val.id">
                <div :class="merchantCss + '-input-result-row'" @click="itemSelected(val)">
                    <span style="font-size:small">
                        {{ val.label }}
                    </span>
                    <span v-if="val.best_product_price > 0" style="font-size:small">
                        jusqu'à {{ val.best_product_price + ' €' }} 
                    </span>
                    <span style="font-size:small" v-if="val.best_product_price <= 0">
                        à recycler
                    </span>
                </div>
            </div>
        </div>  
    </div>
</template>
