<script type="text/ecmascript-6">
export default {
    methods: {

        goToOrdersDashboard() {

            this.$router.push({name: 'dashboardOrders'})
        }
    }
}
</script>
<template>
    <div class="card shadow-lg mt-4">
        <div class="card-body text-center">
            <p style="font-size:0.8125rem">Vous avez une reprise en cours ?</p>
            <button @click="goToOrdersDashboard" class="btn btn-primary btn-sm">Suivre ma reprise</button>
        </div>
    </div>
</template>