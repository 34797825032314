<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import orderService from '@/services/order.js'
import { uuid } from 'vue-uuid'

export default {

    components: {BasketLayout, SidePanelHeader}, 
    mixins: [merchantMixin, orderMixin],
    data() {
        return {
            previewImages: [],
            files: [],
            maxNbrFiles: 1,
            storageUsed: 0,
            isLoading: false,
            error: null
        }
    },
    methods: {
        async uploadInvoice() {

            this.isLoading = true
            this.$tracking.logEvent('Click Upload Invoice', {'Order ID': this.orderId, 'Page Name': 'invoice'})

            try {

                this.error = null
                let response = await orderService.uploadInvoice(this.orderId, this.files[0].file)

                if (response.data) {

                    let query = null;
                    if (this.$route.query.f == 1) {

                        query = {f:1}
                    }

                    this.$router.push({name: 'transferIframe', params: {id: this.order.public_id}, query})

                    this.isLoading = false
                }
            } 
            catch (error) {

                this.isLoading = false
                this.error = 'Un problème est survenu, veuillez contacter le service client'
            }
        },
        addFile(e) {

            this.$tracking.logEvent('Click Add File', {'Order ID': this.orderId, 'Page Name': 'invoice'})

            let droppedFiles = e.dataTransfer != undefined ? e.dataTransfer.files : e.target.files

            if (droppedFiles.length > this.maxNbrFiles) {
                
                alert('Vous ne pouvez pas importer plus de ' + this.maxNbrFiles + ' photo(s)');
                
                return;
            }

            var error
            var newDroppedFiles = []

            for (let i = 0; i < droppedFiles.length; i++) {
                const element = droppedFiles[i];

                if (element.size < 2050000) {

                    newDroppedFiles.push(element)
                    this.storageUsed += element.size
                }
                else {

                    error = 'Une ou plusieurs photos ont été retirées car elles dépassent les 2 Mo.'
                }
            }
            this.$emit('updateStorageData', this.storageUsed)
            droppedFiles = newDroppedFiles

            if (!droppedFiles) return
            ([...droppedFiles]).forEach(f => {
                
                let id = uuid.v4()
                this.files.push({id: id, file: f})
                this.addPreview(id, f)
                this.$emit('listingSteps')
            });

            if (error) {

                alert(error);
            }
        },
        clickOnInput() {

            this.$refs.fileInput.click();
        },
        removeFile(id) {

            this.files = this.files.filter(file => {
                
                this.storageUsed - file.size
                return file.id != id
            })
            this.previewImages = this.previewImages.filter(image => {

                return image.id != id
            })
        },
        addPreview(id, file) {

            let reader = new FileReader
            reader.onload = e => {

                let preview = e.target.result

                this.previewImages.push({id: id, preview})
            }

            return reader.readAsDataURL(file)
        },
    },
    computed: {

    },
    watch: {
        isOrderLoaded() {

            this.$tracking.pageView('invoice', 'Iframe Order', {'Order ID': this.order.public_id})
        }
    }
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="'Facture'" />
        <div class="iframe-content" v-if="merchant && order">
            <h3>Télécharger votre facture d'achat</h3>
            <p>
                Merci de fournir la facture d'achat de votre  {{ order.label }}.
            </p>
            <input class="d-none" id="fileInput" ref="fileInput" :multiple="maxNbrFiles > 1" type="file" @change="addFile" accept="application/pdf,image/jpg,image/jpeg,image/png,image/gif">
            <div class="d-flex align-items-center">
                <button :disabled="files.length === maxNbrFiles" class="btn btn-info  me-4 my-3" @click="clickOnInput">
                    <span>Chargez votre facture</span>
                </button>
            </div>
            <div :key="preview.id" v-for="preview in previewImages" class="row p-2 d-flex flex-row">
                <object style="width:400px;height:300px" class="m-2 border col-12" :data="preview.preview"></object>
                <span @click="removeFile(preview.id)" class="bg-white text-danger ms-1 px-2 delete-btn border col-1">X</span>
            </div>
            <button v-if="files.length === maxNbrFiles" class="btn btn-primary me-4 mt-3 mb-3" @click="uploadInvoice">
                <span :disabled="isLoading">Confirmer l'envoi de votre facture  <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></span>
            </button><br>
            <span class="text-danger" v-if="error != null">{{ error }}</span>
        </div>
    </basket-layout>
</template>
<style scoped>
h3 {

    font-size: var(--iframe-font-size-h3);
    font-weight: bold;
    margin: 0px;
}
</style>