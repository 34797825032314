import axios from 'axios'

export default {

    getCSRF() {

        var path = '/sanctum/csrf-cookie'

        return axios.get(path);
    },
    login(email, password, basketId, merchantId) {

        var path = '/api/customer/login'
        var data = {
            email: email,
            password: password,
            basketId: basketId,
            merchantId: merchantId,
            app: 'customer'
        }

        return axios.post(path, data)
    },
    bypassLogin(email, merchantId) {

        let path = '/api/customer/login'
        let data = {
            email: email,
            password: '',
            bypass: 1,
            merchantId: merchantId,
            app: 'customer'
        }

        return axios.post(path, data)
    },
    logout() {

        var path = 'api/customer/logout'

        return axios.get(path)
    },
    getCurrentUser() {

        var path = '/api/customer/user'

        return axios.get(path)
    },
    register(email, password, isCGUAccepted, basketId, firstName, lastName, address, phoneNumber, listingId) {

        var path = '/api/customer/register'
        var data = {
            email: email,
            password: password,
            basketId: basketId,
            lastName: lastName,
            firstName: firstName,
            address: address,
            phoneNumber: phoneNumber,
            listingId: listingId,
            acceptMangoPayTerms: isCGUAccepted
        }

        return axios.post(path, data)
    },
    forgotPassword(email, merchantId) {

        var path = '/api/forgot-password'
        var data = {
            email: email,
            app: 'customer',
            merchantId: merchantId
        }

        return axios.post(path, data)
    },
    resetPassword(token, email, password, passwordConfirmation, merchantId) {

        var path = '/api/reset-password'
        var data = {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
            app: 'customer',
            merchantId: merchantId
        }

        return axios.post(path, data)
    },
    ssoAuth(basketPublicId, listingPublicId, urlReturn, merchantId, ssoType, orderPublicId) {

        var path ='/api/auth/redirect/' + ssoType
        var data = {
            basketPublicId : basketPublicId,
            listingPublicId : listingPublicId,
            urlReturn: urlReturn,
            merchantId : merchantId,
            orderPublicId: orderPublicId
        }

        return axios.post(path, data)
    }
}
