<script type="text/ecmascript-6">
import BasketLayout from '@/layouts/BasketV2.vue'
import orderService from '@/services/order'
import merchantMixin from '@/mixins/merchant'
import MangoCardForm from '@/components/basket/MangoCardForm.vue'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'

export default {

    components: {BasketLayout, MangoCardForm, AcceptedOffer, ProductCard, ServicesCard, ProductCaption},
    mixins: [merchantMixin],
    data() {
        return {
            mangoCardFormKey: 1,
            isLoading: false,
            error: '',
            order: null,
            isSucceeded: false
        }
    },
    methods: {
        handleError() {

            this.mangoCardFormKey = this.mangoCardFormKey + 1
            this.error = 'Une erreur est survenue. Veuillez réessayer ou contacter le support.'
            this.isLoading = false
        },
        handlePaymentComplete(status) {

            if (!this.isProduction) {

                console.log('handlePaymentComplete', status)
            }

            if (status == 'SUCCEEDED') {

                this.isSucceeded = true
            }
            else {

                this.error = 'Une erreur est survenue. Veuillez réessayer ou contacter le support.'
            }
        },
        createCardRegistration() {

            if (!this.isProduction) {

                console.log('createCardRegistration', status)    
            }

            this.isLoading = true
        },
        async loadOrder(orderPublicId) {

            let withCanBeImmediat = false
            let withRenewPreauth = true

            try {

                let t1 = performance.now()
                let response = await orderService.get(orderPublicId, withCanBeImmediat, withRenewPreauth)
                let t2 = performance.now()
                this.order = response.data

                this.$tracking.logEvent('Performance', {'Function': 'getOrder', 'Time': Math.round((t2 - t1))})

                await this.loadMerchantOrder(this.order.merchant)
            }
            catch (error) {
                    
                this.$router.push({name: 'home'})
            }
        },
    },
    created() {

        this.loadOrder(this.orderPublicId)
    },
    computed: {
        orderPublicId() {

            return this.$route.params.id
        },
        isOrderLoaded() {

            return this.order != undefined
        },
        orderPrice() {

            if (this.isOrderLoaded == false) return 0

            return this.order.price
        }
    },
    watch: {

        isOrderLoaded(val) {

            if (val == true) {

                if (this.order.can_renew_preauth != true) {

                    this.$router.push({name: 'orderConfirm', params: {id: this.orderPublicId} })
                }
            }
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="isOrderLoaded">
            <div v-if="isSucceeded == true" class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Votre pré-authrorisation a été renouvelée</h3>
                        <p>Cela vous permet d'attendre l'arrivée de votre nouveau produit, avant d'envoyer votre "{{ order.label }}" pour être repris.</p>
                        <p>Votre précédente pré-authorisation a été annulée. Si celle ci était visible sur votre compte bancaire, elle devrait bientôt disparaître.</p>
                    </div>
                </div>
            </div>
            <div v-else-if="order.can_renew_preauth" class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Renouvellement de la pré-authorisation</h3>
                        <p>Vous pouvez renouveller votre pré-authorisation, ce qui vous permettra de repousser l'envoi de votre produit.</p>
                        <div class="card mb-2 w-100">
                            <div class="card-body">
                                <small>Pré-autorisation non débitée : <strong>{{order.price | currency}}</strong></small>
                            </div>
                        </div>
                        <div v-if="error" class="mb-2 alert alert-danger"><small>{{ error }}</small></div>
                        <MangoCardForm 
                            :key="mangoCardFormKey"
                            @error="handleError" 
                            @paymentComplete="handlePaymentComplete"
                            :orderPublicId="order.public_id" 
                            :amount="order.price * 100" 
                            @createCardRegistration="createCardRegistration"
                        />
                    </div>
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption v-if="merchant" :hasImmediat="merchant.can_have_immediat_coupon" />
                </div>
            </div>
        </div>
    </basket-layout>
</template>
