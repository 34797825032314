<script type="text/ecmascript-6">

import Layout from '@/layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'
import MerchantLink from '@/components/basket/MerchantLink.vue'

export default {
    components: {Layout, MerchantLink},
    mixins: [merchantMixin],
    created() {

        this.$tracking.pageView('error404', 'basket')
        this.loadMerchantBySubdomain()
    }
}
</script>

<template>
    <layout :withBreadCrumbs="false">
        <div class="mt-4 text-center">
            <h3>Votre panier a expiré</h3>
            <p class="mt-4" v-if="merchant != undefined">     
                Pas de soucis !
                <br/>Revendez votre produit à partir de <merchant-link :merchant="merchant" />
            </p>
        </div>
    </layout>
</template>
