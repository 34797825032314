<script type="text/ecmascript-6">
export default {
    props: ['counter'],
    computed: {

        fromOrderPrice() {

            return this.counter.from_price + this.counter.from_contribution_amount
        },
        toOrderPrice() {

            return this.counter.to_price + this.counter.to_contribution_amount
        }
    }
}
</script>
<template>
    <div class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4 mb-3">
            <div class="col-6">
                <span>Offre initiale</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-warning"><del>{{fromOrderPrice | currencyInteger}}*</del></h4>
            </div>
        </div>
        <div v-if="counter.status == 'refused'" class="row mb-3">
            <div class="col-6">
                <span>Offre refusée</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-danger"><del>{{toOrderPrice | currencyInteger}}</del></h4>
            </div>
        </div>
        <div v-else class="row mb-3">
            <div class="col-6">
                <span>Contre-offre<span v-if="counter.status == 'accepted'"> acceptée</span></span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 class="text-success">{{toOrderPrice | currencyInteger}}</h4>
            </div>
        </div>
    </div>
</template>
