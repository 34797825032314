import Vue from 'vue'
import VueRouter from 'vue-router'
import BasketRoutes from './basket.js'
import DashboardRoutes from './dashboard.js'
import IframeRoutes from './iframe.js'
import TestRoutes from './test.js'
import PageNotFound from '../views/PageNotFound.vue'
import SignIn from '../views/auth/SignIn.vue'
import VueMeta from 'vue-meta'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Donation from '../views/Donation.vue'
import Homepage from '../views/basket/Homepage.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    ...BasketRoutes,
    ...DashboardRoutes,
    {
        path: '/signIn',
        component: SignIn,
        name: 'signIn'
    },
    {
        path: '/forgotPassword',
        component: ForgotPassword,
        name: 'forgotPassword'
    },
    {
        path: '/resetPassword/:token',
        component: ResetPassword,
        name: 'resetPassword'
    },
    {
        path: '/donation',
        component: Donation,
        name: 'donation'
    },
    {
        path: '/',
        component: Homepage,
        name: 'Homepage',
    },
    { 
        path: "*", 
        component: PageNotFound,
        name: 'pageNotFound'
    }
]

routes.push(...IframeRoutes)

if (process.env.VUE_APP_ENVIRONMENT != 'production') {

    routes.push(...TestRoutes)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
