import merchantService from '@/services/merchant'
import { uuid } from 'vue-uuid'

export default {

    computed: {
        merchant() {
            
            return this.$store.getters.merchant
        },
        isMerchantLoaded() {

            return this.merchant != undefined
        },
        merchantName() {

            if (!this.isMerchantLoaded) return ''

            return this.merchant.white_label_domain ? this.merchant.name : 'GREENDID'
        },
        merchantTitle() {

            if (!this.isMerchantLoaded) return ''

            return this.merchant.white_label_domain ? this.merchant.name : 'Greendid x ' + this.merchant.name
        },
        merchantCss() {

            if (this.domain.includes('leclercroques') || this.subdomain.includes('leclercroques')) {
                
                return 'leclercroques';
            }
            
            if (this.domain === 're-mind.eco') {
                
                return 'remind'
            }
            
            if (this.subdomain === 'carrefour') {
                
                return 'carrefour'
            }
            
            if (this.subdomain === 'jerevends') {
            
                return 'jerevends'
            }

            if (this.domain.includes('fnac.com') || this.domain.includes('fd-recette.net') || this.domain.includes('fnac.test')) {

                return 'fnac-reprise'
            }

            if (this.domain.includes('darty.com') || this.domain.includes('darty.test')) {

                return 'darty-reprise'
            }

            if (this.domain.includes('bricomarche') || this.subdomain.includes('bricomarche')) {
                
                return 'bricomarche'
            }

            return 'customer'
        },
        merchantIdForAuth() {

            if (this.isMerchantLoaded === false) return null

            if (this.merchant.attach_users == false) return null

            return this.merchant.id
        },
        subdomain() {

            return window.location.hostname.split('.')[0]
        },
        domain() {

            return window.location.hostname.substring(
                window.location.hostname.indexOf('.') + 1
            )
        }
    },
    methods: {
        isCurrentUserAllowed(merchant) {

            let user = this.$store.getters.user

            if (user == undefined) return true
        
            if (merchant.attach_users == true) {

                if (merchant.id == user.merchant_id) return true
            }
            else {

                if (user.merchant_id == 0) return true
            }

            return false
        },
        loadMerchantOrder(merchantOrder) {

            if (this.merchant == undefined || this.merchant.id != merchantOrder.id) {
                
                this.$store.commit('setMerchant', merchantOrder)
            }

            if (this.merchant != undefined) {

                let subdomain = this.subdomain
                if (subdomain != this.merchant.subdomain) {

                    window.location = window.location.href.replace('://'+subdomain+'.', '://'+this.merchant.subdomain+'.')
                }
            }
        },
        async loadMerchant(merchantId) {

            if (this.merchant == undefined || this.merchant.id != merchantId) {

                let t1 = performance.now()
                let response = await merchantService.getMerchant(merchantId)
                let t2 = performance.now()
                let merchant = response.data

                this.$tracking.logEvent('Performance', {'Function': 'getMerchant','Time': Math.round((t2 - t1))})

                if (this.isCurrentUserAllowed(merchant)) {

                    this.$store.commit('setMerchant', merchant)
                }
                else {

                    this.$store.dispatch('signOut')
                    this.$router.push({name: 'signIn'})
                }
            }

            if (this.merchant != undefined) {

                let subdomain = this.subdomain
                if (subdomain != this.merchant.subdomain) {

                    window.location = window.location.href.replace('://'+subdomain+'.', '://'+this.merchant.subdomain+'.')
                }
            }
        },
        async loadMerchantBySubdomain() {

            if (this.merchant == undefined) {

                let subdomain = this.subdomain
                let domain = this.domain

                if (domain.includes('greendid')) {
                    
                    if (subdomain.includes('leclercroques') == false && subdomain.includes('bricomarche') == false && subdomain.includes('fnac-reprise') == false) {
 
                        domain = null
                    }
                } 
                
                if (subdomain != 'customer') {

                    let t1 = performance.now()
                    let response = await merchantService.getMerchantBySubdomain(subdomain, domain)
                    let t2 = performance.now()    
                    let merchant = response.data
                    
                    this.$tracking.logEvent('Performance', {'Function': 'getMerchantBySubdomain', 'Time': Math.round((t2 - t1))})

                    if (this.isCurrentUserAllowed(merchant)) {

                        this.$store.commit('setMerchant', merchant)
                    }
                    else {
    
                        this.$store.dispatch('signOut')
                        this.$router.push({name: 'signIn'})
                    }
                }
            }
        },
        setMerchantUserId(merchant, merchantUserId) {

            if (merchant.is_umid_mandatory == 1) {

                if (merchantUserId == '') {

                    merchantUserId = null
                }
            }
            else {

                merchantUserId = this.transformMerchantUserId(merchantUserId)
            }

            this.$store.commit('setMerchantUserId', merchantUserId)
        },
        closeIframe() {

            window.parent.postMessage('closeIframe', '*')
        },
        transformMerchantUserId(merchantUserId, merchantId) {

            if (merchantUserId == undefined 
                || merchantUserId == ''
                || (merchantId == 6211121839 && merchantUserId == '1fbf23b30-5b0d-4cb1-bf76-c1d5ee38f9c5')
                || (merchantId == 6211121839 && merchantUserId == '193a45081-8d80-f6ff-16b4-d40a49cc2648')  
            ) {

                merchantUserId = 'random-' + uuid.v4();
            }

            return merchantUserId
        }
    }
}
