<script>
import Btn from '@/components/iframe/Button.vue'
import mediaMixin from '@/mixins/media'

export default {
    mixins: [mediaMixin],
    components:{Btn},
    data() {
        return {
            isDragging: false,
            files: [],
            error:null
        };
    },
    methods: {
        onChange() {
            
            if ((this.$refs.file.files.length + this.files.length) > 2) {

                this.error = 'Vous ne pouvez pas importer plus de 2 photos'
                return 
            }

            this.files.push(...this.$refs.file.files);
            
            this.$emit('updateFiles', this.files)
        },
        dragover(e) {

            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {

            this.isDragging = false;
        },
        drop(e) {

            this.error = ''
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        triggerFileInput() {

            this.error = ''
            this.$refs.file.click();
        },
        removeFile(file) {

            this.error = ''
            this.files = this.files.filter(f => {
                return f != file
            })

            if (this.files.length > 2) {

                this.error = 'Vous ne pouvez pas importer plus de 2 photos'
            }
            this.$emit('updateFiles', this.files)
        },
    },
    computed: {
        rightIcon() {

            if (this.merchant && this.merchant.id == 7764956693) {

                return this.getLocalImage('file.svg', this.merchantCss)
            }

            return ''
        }
    }
}
</script>
<template>
    <div>
        <div
            id="drag-drop-file"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
        >
            <input
                type="file"
                multiple
                name="file"
                id="fileInput"
                class="hidden-input"
                @change="onChange"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
            />
            <p v-if="files < 1" style="color:var(--iframe-border-grey-dark)">Faites glisser vos fichiers ici</p>
            <div id="file-name-displayed-wrapper" v-else >
                <div id="file-name-displayed-row" v-for="(file,index) in files" v-bind:key="index">
                    <p style="color:var(--iframe-border-grey-dark)">{{file.name}}</p>
                    <div @click="removeFile(file)" class="file-name-cancel-btn">
                        x
                    </div>
                </div>
            </div>
        </div>
        <p id="import-files-text">ou ajoutez vos fichiers :</p>
        <div @click="triggerFileInput()">
            <btn class="btn-iframe btn-iframe-light" 
                :text="'Sélectionner ma pièce d\'identité'" 
                :color="'black'" 
                :backgroundColor="`var(--iframe-color-white)`" 
                :rightIcon="rightIcon"
            /> 
        </div>
        <p id="error-message" style="color:red" v-if="error" >{{ error }}</p>
    </div>
</template>  
<style scoped>

p {
    font-weight: var(--iframe-font-weight-normal);
    font-size: var(--iframe-font-size-p);
    margin: 0;
}

#drag-drop-file {
    width: 100%;
    height: 160px;
    border-radius: 4px;
    border: 1px var(--iframe-border-grey-normal) dashed;
    background-color: var(--iframe-border-grey-light);
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

#import-files-text {
    color: black;
    margin: 16px 0px 8px 0px;
}

#file-name-displayed-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:10px;
}

#file-name-displayed-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}

.file-name-cancel-btn {
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 2px;
    border: 1px var(--iframe-border-grey-normal) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding:10px;
    margin: 0;
}

#error-message {
    color:red;
    padding: 10px 0px;
}
</style>