<script type="text/ecmascript-6">

import Loader from '../iframe/Loader.vue'

export default {
    props: {
        'backgroundColor': String,
        'color': String,
        'text': String,
        'border': String,
        'value': String,
        'disabled': {
            type: Boolean,
            default: false
        },
        'isLoading': {
            type:Boolean,
            default: false
        },
        'rightIcon': {
            type: String,
            default: ''
        }
    },
    components:{Loader},
    computed: {
        style() {
            return {
                backgroundColor: this.disabled ? '#D8D8D8' : this.backgroundColor,
                height: '40px',
                color: this.color,
                width: '100%',
                border: '2px solid ' + (this.border ? this.border : 'var(--iframe-border-grey-dark)'),
            };
        },
    },
    methods: {
        handleClick() {
            if (!this.isLoading) {

                this.$emit('click', this.disabled);
            }
        }
    }
}
</script>
<template>
    <button :disabled="disabled" @click="handleClick" :style="style" class="btn-iframe" :class="{'disabled' : isLoading || disabled}" >
        <img v-if="rightIcon" :src="rightIcon" class="me-2" />
        <span v-if="!isLoading" class="btn-iframe-text">{{text}}</span>
        <loader id="loader-iframe" v-else-if="isLoading" />
    </button>
</template>
<style scoped>
.btn-iframe-text{
    font-weight: var(--iframe-font-weight-normal);
    font-size: var(--iframe-font-size-p);
}

.btn-iframe {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-frame {
    position: relative;
    right:0px;
}

.disabled {
    cursor:auto;
}
</style>
