<script type="text/ecmascript-6">
import mediaMixin from '@/mixins/media'
export default {
    props: ['maxBestOffer', 'bonus'],
    mixins: [mediaMixin],
    methods: {
        getColor() {

            if (this.bonus == undefined) return ''

            return this.bonus.color
        },
        getBonusAmount() {

            let value = this.bonus.bonus_amount / 100
            if (this.bonus.bonus_amount_type == 'percent') {

                return value + ' %'
            }

            return '+' + value + ' €'
        },
        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getPublicId() {

            if (this.bonus == undefined) return ''

            return this.bonus.public_id
        },
        vouchSelected() {

            let ref = 'inputCampaign' + this.getPublicId()
            if (this.$refs[ref])
                this.$refs[ref].checked = true

            this.$emit('vouchSelected', { price: this.maxBestOffer.price, campaign: this.bonus, type: 'coupon' })
        }
    }
}
</script>

<template>
    <section>
        <div v-if="bonus" class="d-flex flex-row mt-4 ms-3" @click="vouchSelected">
            <div class="card shadow-lg rounded-0 col-7 mx-0">
                <div class="row mx-0 h-100">
                    <div class="col-1 px-0 row">
                        <div :class="'bg-' + getColor()" class="col-7 px-0"></div>
                    </div>
                    <div class="col-2 d-flex align-items-center px-2">
                        <input class="form-check-input" type="radio" :ref="'inputCampaign'+getPublicId()" :id="'campaign-' + getPublicId()" name="campaign">
                    </div>
                    <div v-if="maxBestOffer.price > 0" class="card-body pt-3 col-9">
                        <p class="mb-1">
                            {{merchant.coupon_name.toUpperCase()}} {{ merchant.coupon_platform_name.toUpperCase() }}<img v-if="merchant.id == 9884974566" width="40px" :src="getMerchantAsset(9884974566, 'pastille-carrefour.png')"/>
                            <br/>
                            <strong>PRODUITS {{ bonus.brand.name.toUpperCase() }}</strong>
                        </p>
                        <h2 :class="'text-' + getColor()" class="d-flex mb-0">
                            <strong>{{maxBestOffer.price | currency}}*</strong>
                            <strong class="fs-xs ms-3" v-if="merchant.can_have_immediat_bonus != 'never'">utilisables dès maintenant (1)</strong>
                        </h2>
                        <strong v-if="maxBestOffer.price_raw != undefined" style="color:#1F56BB" class="fs-sm">dont {{ (maxBestOffer.price - maxBestOffer.price_raw) | currency }} de bonus {{ merchant.coupon_platform_name }}</strong>
                        <p class="font-size-xxs mt-3 me-3 mb-0">
                            Valable {{merchant.coupon_validity_period}} pour l'achat de produits {{bonus.brand.name}} hors marketplace <span v-html="merchant.coupon_validity_places"></span>
                        </p>
                    </div>
                    <div v-else class="card-body pt-3 col-9">
                        <p class="text-center"><strong>Recyclage</strong></p>
                        <p>Nous n'avons pas d'offre sur ce produit.<br/>Expédiez le <strong>gratuitement</strong> et il sera recyclé par notre partenaire.</p>
                    </div>
                </div>
            </div>
            <div class="card shadow-lg rounded-0 col-5 px-0 vouch-bonus-separator">
                <div class="mx-2 p-3">
                    <p class="mb-0">BONUS<br/><strong>{{getBrandName()}}</strong></p>
                    <h2 :class="'text-' + getColor()"><strong>{{getBonusAmount()}}</strong></h2>
                    <p class="font-size-xxs mt-3 me-3 mb-0">
                        <span v-if="!bonus.is_cash_refund">Sous la forme de code de réduction {{merchant.coupon_platform_name}}</span>
                        <span v-html="bonus.legal_application_label"></span>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
