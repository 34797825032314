import axios from 'axios'

export default {

    list() {

        var path = '/api/customer/brands'

        return axios.get(path)
    }
}
