<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'
import faqMixin from '@/mixins/faq'
import merchantMixin from '@/mixins/merchant.js'

export default {
    components: {BasketLayout, SidePanelHeader, Btn}, 
    mixins:[faqMixin, merchantMixin],
    computed: {


    },
    methods: {
        closePanel() {

            window.parent.postMessage({type: 'close'}, '*')
        },
        redirectTo(locationName) {

            this.$router.push({name: locationName})
        },
    },
    created() {

        this.loadMerchantBySubdomain()
        this.$tracking.pageView('Error', 'Iframe')
    },  
}
</script>
<template>
    <basket-layout>
        <side-panel-header id="layout" :title="''" />
            <div v-if="isMerchantLoaded" id="not-found-section">
                <div id="not-found-content">
                    <img id="content-header-img" src="/assets/img/icon-info.svg" alt="">
                    <h2>Nous sommes désolé, une erreur est survenue</h2>
                    <p>Pour plus d'informations, contactez le service client : {{ getContactEmail() }}</p>
                    <div id="btns-wrapper" >
                        <btn @click="closePanel" :text="'Retour au panier'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`"/> 
                        <btn @click="redirectTo('SearchFormIframe')" :text="'Faire une nouvelle estimation'" :color="'black'" :backgroundColor="`var(--iframe-color-white)`" /> 
                    </div>
                </div>
            </div>
    </basket-layout>
</template>
<style scoped>
#layout {
    height: 100vh;
    overflow: hidden;
}

#not-found-content {
       height: calc(100vh - 100px);
    padding:0 32px 32px 32px;
}

#content-header-img {

    margin-bottom: 20px;
}
#btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin:30px 0px;
    width: 100%;
}
#not-found-section, #not-found-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#not-found-section{
    width: 100%;
    height: 85vh;
}
</style>
