<script type="text/ecmascript-6">
export default {
    props: {
        'brand': String,
        'description': String,
        'img': String,
        'maxBestOffer': String
    },

}
</script>
<template>
    <div id="product-card">
        <div id="product-card-main-section">

            <div id="product-card-top-section">
                <div id="product-card-img-container">
                    <img id="product-card-img" :src=img alt="">
                </div>
                <div id="product-card-texts">
                    <div v-if="maxBestOffer">

                        <span id="product-card-title">
                            Offre de reprise estimée : 
                        </span>
                        <span  id="product-card-label-price">jusqu'à {{maxBestOffer}} €</span>
                    </div>
                    <span id="product-card-product-label">

                        {{brand}} {{description}}
                    </span>
                </div>
            </div>
            <span v-if="maxBestOffer" id="product-card-bottom-label">

                Offre exclusive web, prix non garanti en magasin.
            </span>
        </div>
    </div>
</template>

<style scoped>

* {
    font-family:var(--iframe-font-family);
}

#product-card {

    border: 1px var(--iframe-border-grey-light) solid;
    border-radius: 4px;
    width: 100%;
    height: fit-content;
    display: flex;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

#product-card-top-section {

    display: flex;
    width: 100%;
}

#product-card-texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left:10px;
}

#product-card-bottom-label {
    font-weight: var(--iframe-font-weight-normal);
    font-size: var(--iframe-font-size-small);
    color: var(--iframe-border-grey-dark);
}

#product-card-main-section {
    padding: 10px;
}

#product-card-label-price {

    font-weight: var(--iframe-font-weight-bold);
    color: var(--iframe-color-price);
}
#product-card-title {

    font-weight: var(--iframe-font-weight-bold);
    font-size:var(--iframe-font-size-h3);
}

#product-card-img-container {

    max-width: 80px;
    max-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
