<script>
import merchantMixin from '@/mixins/merchant'
import categoryService from '@/services/category.js'
import mediaMixin from '@/mixins/media'
import HomepageBonuses from '@/components/home/Bonuses.vue'

export default {
    mixins: [merchantMixin, mediaMixin],
    components: {HomepageBonuses},
    data() {

        return {

            categories: [],
            bonuses: [
                {
                    startDate: '2024-07-30',
                    endDate: '2024-08-26',
                    couponValidityDate: '26/08/2024',
                    amount: 50,
                    productName: 'd\'un Google Pixel 7 ou 8 éligible',
                    img: 'https://media.carrefour.fr/medias/45871de7c9b837a6a2d6b277dd1a4a88/p_1500x1500/0840244707934-photosite-2024524-142723-0.jpg',
                    sellType: 'ancien produit high tech éligible, de moins de 250 €',
                    couponValidityPlace: 'sur carrefour.fr. Offre cumulable avec les offres en cours hors code promo'
                }
            ]
        }
    },
    methods: {

        async loadCategories() {

            let response = await categoryService.list(9884974566)

            this.categories = response.data
        },
        redirectToSearchForm(category = null) {

            if (category != null) {

                if (category.is_only_async == true) {

                    this.goToListing(category)
                }
                else {

                    this.$router.push({name: 'searchForm', query: { 'cid': category.id}})
                }
            }
            else {

                this.$router.push({name: 'searchForm'})
            }
        },
        goToListing(category) {

            let query = {}

            if (category) {

                query['cid'] = category.id
            }

            this.$router.push({name: 'listing', query: query})
        }
    },
    created() {

        this.loadCategories()
    },
    computed: {

        availableBonuses() {

            let todaysDate = Date.now()
            return this.bonuses.filter(bonus => {

                let bonusStartDate = new Date(bonus.startDate)
                let bonusEndDate = new Date(bonus.endDate)

                if ((todaysDate >= bonusStartDate.getTime() && todaysDate <= (bonusEndDate.getTime() + 82800000))) {

                    return true
                }

                return false
            })
        }
    }
}
</script>

<template>
    <main>
        <img class="img-fluid w-100" src="/assets/img/carrefour/banniere-carrefour-reprise.png" />
        <div class="container mt-4">
            <div class="row">
                <div class="col-12 col-md-5 card shadow-lg">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title carrefour-homepage-text-color">Recevez immédiatement une carte cadeau électronique</h5>
                        <p>utilisable tout de suite pour un nouvel achat,<br/>avant même d'expédier votre produit vendu</p>
                        <div class="d-flex align-items-end justify-content-between mt-auto">
                            <div>
                                <button @click="redirectToSearchForm(null)" class="btn btn-carrefour">Je revends</button>
                                <a href="#4-steps" class="carrefour-how-link ms-2"><small>Comment ça marche ?</small></a>
                            </div>
                            <img src="/assets/img/carrefour/carte-cadeau.png" />
                        </div>
                    </div>
                </div>
                <div class="offset-md-2 col-12 col-md-5 card shadow-lg mt-2 mt-md-0">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title carrefour-homepage-text-color">Ou recevez du cash</h5>
                        <p>par virement bancaire<br/>après réception et validation du produit vendu</p>
                        <div class="d-flex align-items-end justify-content-between mt-auto">
                            <div>
                                <button @click="redirectToSearchForm(null)" class="btn btn-carrefour">Je revends</button>
                                <a href="#4-steps" class="carrefour-how-link ms-2"><small>Comment ça marche ?</small></a>
                            </div>
                            <img src="/assets/img/carrefour/cash.png" />
                        </div>
                    </div>
                </div>
            </div>
            <h3 class="mt-5 mb-5 text-center">C'est <span class="carrefour-homepage-text-color">simple</span>, <span class="carrefour-homepage-text-color">rapide</span> et vous revendez vos produits <span class="carrefour-homepage-text-color">au meilleur prix.</span></h3>
            <section v-if="availableBonuses.length > 0">
                <p class="carrefour-homepage-text-color">En ce moment :</p>
                <homepage-bonuses :bonuses="availableBonuses" :merchantCss="merchantCss" />
            </section>
            <h4 id="4-steps" class="mt-5">
                Comment <span class="carrefour-homepage-text-color">revendre</span> mon produit en 4 étapes ? <button @click="redirectToSearchForm(null)" class="btn btn-carrefour ms-4">Je revends</button>
            </h4>
            <div class="row mt-4">
                <div :class="'offset-md-1 col-6 col-md-2 bg-white border-' + merchantCss">
                    <div class="text-center d-flex flex-column align-items-center m-3 text-start">
                        <img class="col-4" src="/assets/img/carrefour/revente-choix-produit.png" alt="formulaire de revente">
                        <div><span :class="merchantCss + '-homepage-text-color'">1</span> - Je choisis <span :class="merchantCss + '-homepage-text-color'">mon produit à revendre</span></div>
                    </div>
                </div>
                <div :class="'col-6 offset-md-1 col-md-2 bg-white border-' + merchantCss">
                    <div class="text-center d-flex flex-column align-items-center m-3">
                        <img class="col-4" src="/assets/img/carrefour/etat-produit.png" alt="état du produit">
                        <div><span :class="merchantCss + '-homepage-text-color'">2</span> - Je décris <span :class="merchantCss + '-homepage-text-color'">l'état de mon produit</span></div>
                    </div>
                </div>
                <div :class="'col-6 offset-md-1 mt-2 mt-md-0 col-md-2 bg-white border-' + merchantCss">
                    <div class="text-center d-flex flex-column align-items-center m-3">
                        <img class="col-4" src="/assets/img/carrefour/carte-cadeau-cash.png" alt="carte cadeau ou cash">
                        <div><span :class="merchantCss + '-homepage-text-color'">3</span> - Je choisis entre <span :class="merchantCss + '-homepage-text-color'">carte cadeau et virement</span></div>
                    </div>
                </div>
                <div :class="'col-6 offset-md-1 mt-2 mt-md-0 col-md-2 bg-white border-' + merchantCss">
                    <div class="text-center d-flex flex-column align-items-center m-3">
                        <img class="col-4" src="/assets/img/carrefour/expedition.png" alt="expedition du colis">
                        <div><span :class="merchantCss + '-homepage-text-color'">4</span> - J'expédie <span :class="merchantCss + '-homepage-text-color'">gratuitement</span> mon produit</div>
                    </div>
                </div>
            </div>
            <h4 class="mt-5">Quels produits je peux revendre ?</h4>
            <div class="d-flex flex-column align-items-center">
                <div class="card shadow-lg mb-3">
                    <div class="card-body">
                        <div class="container d-flex flex-wrap">
                            <div @click="redirectToSearchForm(category)" class="d-flex flex-column col col-sm-3 p-2 bg-white text-center" v-for="category in categories" :key="category.id" style="cursor:pointer;">
                                <div class="mb-auto"><img class="img-fluid" style="max-height:120px" :src="getCategoryAsset(category.img_filename)" :alt="category.name" /></div>
                                <div class="small mt-2">{{category.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="redirectToSearchForm(null)" class="btn btn-carrefour-lg">Je revends</button>
            </div>
        </div>
    </main>
</template>

<style>

.justify-content-space-between {

    justify-content: space-between;

}
.justify-content-space-around {

    justify-content: space-around;

}
</style>
