import authService from '../../services/auth.js'

export default {

    state: {
        authenticated: false,
        user: null
    },
    getters: {
        authenticated: state => { return state.authenticated },
        user: state => { return state.user }
    },
    mutations: {
        setAuthenticated (state, value) {
            
            state.authenticated = value
        },
        setUser (state, value) {
            
            state.user = value
        },
    },
    actions: {
        async signIn({ dispatch }, credentials) {

            let response = await authService.getCSRF()
            if (response) {
            
                await authService.login(credentials.email, credentials.password, credentials.basketId, credentials.merchantId)
                this._vm.$tracking.logEvent('Logged In')
            }

            return dispatch('me')
        },
        async register({ dispatch }, credentials) {

            let response = await authService.getCSRF()
            if (response) {

                await authService.register(credentials.email, credentials.password, credentials.isCGUAccepted, credentials.basketId, credentials.firstName, credentials.lastName, credentials.address, credentials.phoneNumber, credentials.listingId) 
                this._vm.$tracking.logEvent('Registered In')
            }

            return dispatch('me')
        },
        async signOut ({ dispatch }) {

            await authService.logout()
    
            this._vm.$tracking.logEvent('Logged Out')

            return dispatch('me')
        },
        me ({ commit }) {
            return authService.getCurrentUser()
                .then((response) => {

                    commit('setAuthenticated', true)
                    commit('setUser', response.data)

                    this._vm.$tracking.setUserId(response.data.id)

                    return response.data
                    
                }).catch(() => {

                    commit('setAuthenticated', false)
                    commit('setUser', null)

                    return null
            })
        }
    }
}