<script type="text/ecmascript-6">
import BasketLayout from '@/layouts/BasketV2.vue'
import mangoService from '@/services/mango'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'

export default {

    components: {BasketLayout},
    mixins: [orderMixin, merchantMixin],
    data() {
        return {
            currency: "EUR", 
            cardType: "CB_VISA_MASTERCARD", 
            mangoTermsAccepted: true,
            cardInfos: {
                cardNumber: '',
                cardExpirationDate: '',
                cardCvx: ''
            },
            errorMessage: "Une erreur s'est produite, contactez le service client",
            hasError: false,
            isLoading: false,
            deposit: null,
            isWaiting: false
        }
    },
    created() {

        this.$store.commit('setCurrentStep', 30)
 
        if (this.needToLoadDeposit) {

            this.isWaiting = true
        }
    },
    filters: {
        formatCardNumber(value){

            return value ? value.match(/.{1,4}/g).join(' ') : '';
        }
    },
    methods: {
        async createDeposit(orderPublicId, mangoDepositId) {

            try {

                let response = await mangoService.createOrderReservationFromDeposit(orderPublicId, mangoDepositId)
                this.deposit = response.data
                if (this.deposit.status == 'ACCEPTED') {

                    this.$router.push({name: 'orderKyc', query: {pv: 1}})
                }
                else {

                    this.isWaiting = false
                }
            }
            catch (error) {

                this.isWaiting = false
            }
        },
        clearForm() {

            this.$router.push({name: 'mangoCardForm', query: {dc:1}, params: {id: this.order.public_id}})
        },
        updateValue(e){

            this.cardInfos.cardNumber = e.target.value.replace(/ /g,'');
        },
        async createMangoCardDeposit() {

            this.$tracking.logEvent('Click Create Mango Deposit', {'Order ID': this.order.public_id})

            this.errorMessage = "Une erreur s'est produite, contactez le service client"
            this.isLoading = true
            this.hasError = false
            if (!this.inputFilled) {

                this.errorMessage = "Un des champs requis n'est pas rempli"
            }

            try {

                let response = await mangoService.createCardAccount(this.currency, this.cardType, this.mangoTermsAccepted)
                let cardRegistrationInfos = response.data

                let preRegistrationData = null;
                let cardRegistrationId = null;
                let cardActive = false;

                response = await mangoService.postCardInfo(cardRegistrationInfos.cardRegistrationURL, cardRegistrationInfos.data, cardRegistrationInfos.accessKeyRef, this.cardInfos)

                preRegistrationData = response.data

                if (preRegistrationData.startsWith("errorCode")) {
                    
                    this.errorMessage = 'Une erreur est survenue concernant les informations de votre CB. Veuillez vérifier que les identifiants sont correct.'
                }

                cardRegistrationId = cardRegistrationInfos.cardRegistrationId
                cardActive = true;

                let orderPublicId = this.$route.params.id
                let card = await mangoService.updateCardRegistration(cardRegistrationId, preRegistrationData, orderPublicId, cardActive)
                let depositCreatedResponse = await mangoService.createDepositPreAuth(this.order.public_id, card.data.currency, card.data.id, 1)
    
                if (depositCreatedResponse.data['status'] === 'CREATED') {

                    window.location.replace(depositCreatedResponse.data['secureModeRedirectURL'])
                }
                else if(depositCreatedResponse.data['status'] === 'SUCCEEDED') {

                    window.location.replace(depositCreatedResponse.data['secureModeReturnURL'])
                }
                else {

                    this.hasError = true
                    this.isLoading = false
                }
            }
            catch (error) {
        
                this.hasError = true
                this.isLoading = false
                
                this.$tracking.logEvent('Create Mango Deposit Error', {'Order ID': this.order.public_id, 'Error': error.message})
            }
        },
        inputFilled() {

            if (this.cardInfos.cardExpirationDate < 4 || this.cardInfos.cardNumber.length < 16 || this.cardInfos.cardCvx.length < 3) {
                
                return false
            }
           
            return true
        }
    }, 
    computed: {
        formatCardNumber() {

            return this.cardNumber ? this.cardNumber.match(/.{1,4}/g).join(' ') : '';
        },
        needToLoadDeposit() {

            return this.$route.query.depositId != undefined
        },
        depositStatus() {

            if (!this.needToLoadDeposit) {

                return null
            }

            if (this.isWaiting) {

                return 'WAITING'
            }

            if (this.deposit && this.deposit.status == 'ACCEPTED') {

                return 'SUCCEDED'
            }

            return 'FAILED'
        },
        amount() {

            if (this.order == undefined) return 0

            if (this.merchant == undefined) return 0

            let amount = this.order.price
            const deposit = ['always_with_deposit', 'always_with_deposit_no_postponed']

            if (deposit.includes(this.merchant.can_have_immediat_bonus)) {

                amount += this.order.bonus_amount
            }

            return amount
        }
    },
    watch: {
        isOrderLoaded() {

            this.$tracking.pageView('mango card form', 'order', {'Order ID': this.order.public_id, 'Deposit ID': this.$route.query.depositId, 'Status': this.$route.query.status})

            if (this.$route.query.depositId != undefined) {

                this.createDeposit(this.order.public_id, this.$route.query.depositId)
            }
        }
    }
}
</script>
<template>
    <basket-layout>
        <div class="d-flex justify-content-center w-100">
            <div v-if="depositStatus === 'FAILED'" class="m-3 m-md-5">
                <h3>L'autorisation de paiement a échoué</h3>
                <button class="btn btn-info" @click="clearForm">Je saisis ma carte à nouveau</button>
            </div>
            <div v-else-if="depositStatus === 'WAITING'" class="m-3 m-md-5">
                <h3>En attente de validation...</h3>
                <p>L'opération peut prendre quelques instants à être confirmé.</p>
                <button class="btn btn-info" @click="$router.go(0)">Actualiser la page</button>
            </div>
            <div v-else-if="isOrderLoaded" class="m-2 col-md-11 d-flex justify-content-center align-items-center flex-column">
                <table class="card table col-md-10 p-2">
                    <tr>
                        <td><strong>Pré-autorisation non débitée : </strong></td>
                        <td>{{amount | currency}}</td>
                    </tr>
                </table>
                <form  data-form-type="payment" class="card form-group d-flex justify-content-center col-md-10">
                    <div class=" d-flex flex-column row justify-content-center m-3">
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-center">
                                <div class="col-4 my-4">
                                    <img src="/assets/img/cb-visa-mastercard.jpg" alt="cb-visa-mastercard">
                                </div>
                            </div>
                            <div class="d-flex flex-column mb-4 justify-content-center col-md-9">
                                <strong>Numéro de carte<span class="text-danger" >*</span></strong>
                                <input maxlength="19" :value="cardInfos.cardNumber | formatCardNumber" data-form-type="payment,number,credit_card" type="text" @input="updateValue" :class="{'border-success' : (cardInfos.cardNumber.length > 15)}" placeholder="#### #### #### ####">  
                            </div>
                            <div class="d-flex mb-4">
                                <div class="d-flex flex-column justify-content-between col-4">
                                    <strong>Date d'expiration <small>(MMAA) </small> <span class="text-danger" > * </span></strong>
                                    <input maxlength="4" class="col-8 col-md-4" type="text" data-form-type="payment,credit_card,date" v-model="cardInfos.cardExpirationDate" :class="{'border-success' : (cardInfos.cardExpirationDate > 3)}" placeholder="****">
                                </div>
                                <div class="d-flex flex-column justify-content-between col-4">
                                    <strong>Code CVV <span class="text-danger" >*</span></strong>
                                    <input  maxlength="3" class="col-8 col-md-3" type="password" data-form-type="payment,credit_card,cvv" v-model="cardInfos.cardCvx" :class="{'border-success' : (cardInfos.cardCvx.length > 2)}" placeholder="***">
                                </div>
                            </div>
                        </div>
                        <p v-if="hasError" class="text-danger"><small>{{ errorMessage }}</small></p>
                        <div class="d-flex justify-content-space-between flex-column flex-md-row">   
                            <button :disabled="isLoading" class="btn btn-secondary mb-md-0 mb-2">
                                <span>Annuler</span>
                                <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true">
                                </span>
                            </button>
                            <button :disabled="!inputFilled() || isLoading" @click="createMangoCardDeposit" class="btn btn-success">
                                <span>Valider la pré-autorisation</span>
                                <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> 
                            </button>
                        </div>
                    </div>
                </form>
                <div  class="d-flex justify-content-center align-items-center bg-white m-1 px-2">
                    <p style="margin: 0;">Powered by </p>
                    <img style="width: 100px; margin-right: 10px;" src="/assets/img/mango.png" alt="">
                    <img style="width: 70px; margin-right: 10px;" src="/assets/img/arkea2.png" alt="">
                    <img style="width: 90px; margin-right: 10px;margin-top: 10px;" src="/assets/img/payline.jpg" alt="">
                </div>
            </div>
        </div>
    </basket-layout>
</template>

<style scoped>
    .card.table {
        width: auto !important;
    }
</style>
