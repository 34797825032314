<script type="text/ecmascript-6">

import { mapActions } from 'vuex'
import merchantMixin from '@/mixins/merchant'
import mediaMixin from '@/mixins/media'

export default {
    
    mixins: [merchantMixin, mediaMixin],
    methods: {
        ...mapActions({
                signOut: 'signOut',
        }),
        logout() {

            this.signOut()
            this.$router.push({name: 'signIn'})
        }
    },
    created() {

        this.loadMerchantBySubdomain()
    },
    computed: {
        logoPath() {

            let subdomain = window.location.hostname.split('.')[0]
            if (subdomain == 'customer') return '/assets/img/logo-greendid.webp'
            
            if (this.isMerchantLoaded == false) return null

            return this.getMerchantAsset(this.merchant.id, this.merchant.asset_logo_name)
        }
    }   
}
</script>
<template>

    <header class="navbar navbar-expand-lg navbar-light box-shadow-sm">
        <div class="container">
            <!-- Brand -->
            <a class="navbar-brand d-none d-lg-block mr-3 order-lg-1" href="/" style="min-width: 7rem;">
                <img width="142" :src="logoPath" />
            </a>
            <a class="navbar-brand d-lg-none mr-2 order-lg-1" href="/" style="min-width: 2.125rem;">
                <img width="34" :src="logoPath" />
            </a>

            <!-- Toolbar -->
            <div class="navbar-toolbar d-flex align-items-center order-lg-3">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-tool dropdown ml-1 ml-lg-0 mr-n1 mr-lg-2" href="#">
                    <a href="#" class="navbar-tool-icon-box dropdown-toggle"><i class="navbar-tool-icon czi-user"></i></a>
                    <a class="navbar-tool-text ml-n2"><small>Bonjour, {{$store.getters.user.first_name}}</small>Mon compte</a>
                    <div class="dropdown-menu dropdown-menu-right" style="width: 20rem;">
                        <router-link :to="{name: 'settings'}" class="dropdown-item">Paramètres</router-link>
                        <a class="dropdown-item" href="#" @click="logout">Se déconnecter</a>
                    </div>
                </div>
            </div>

            <!-- Collapsible menu -->
            <div class="collapse navbar-collapse mr-auto order-lg-2" id="navbarCollapse">
                <hr class="d-lg-none mt-3 mb-2">
                <ul class="navbar-nav">
                    <li class="nav-item active"><a class="nav-link" href="/dashboard/orders">Liste des ventes</a></li>
                </ul>
            </div>
        </div>
    </header>
</template>
