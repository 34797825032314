import orderService from '@/services/order'
import merchantMixin from '@/mixins/merchant'

export default {

    mixins: {merchantMixin},
    data() {
        return {
            order: null,
        }
    },
    created() {
      
        let withCanBeImmediat = this.withCanBeImmediat && this.withCanBeImmediat === true ? true : false
        this.loadOrder(this.$route.params.id, withCanBeImmediat)
    },
    methods: {
        async loadOrder(orderPublicId, withCanBeImmediat = false) {

            try {

                let t1 = performance.now()
                let response = await orderService.get(orderPublicId, withCanBeImmediat)
                let t2 = performance.now()
                this.order = response.data

                this.$tracking.logEvent('Performance', {'Function': 'getOrder', 'Time': Math.round((t2 - t1))})

                await this.loadMerchantOrder(this.order.merchant)
            }
            catch (error) {
                
                if (window.location.pathname.includes('iframe')) {

                    this.$router.push({name: 'loginIframe', query: {returnUrl: window.location.href.replace(window.location.search, '')}})
                }
                else {
                    
                    this.$router.push({name: 'home'})
                }
            }
        },
        getIframeRouteName() {

            let routeName = ''

            if (this.$route.name == 'orderCancelIframe' && this.order.order_status_id === 1) {
                    
                routeName = 'orderCancelIframe'
            }
            else if (this.$route.name == 'error' || this.order.order_status_id === 20) {

                routeName = 'error'
            }
            else if (this.$route.name == 'ibanIframe' && this.order.order_status_id === 2) {

                routeName = 'ibanIframe'
            }
            else {
                
                if (this.order.order_status_id === 1) {

                    if ((this.order.payment_reservation === null && this.order.coupon_type === 'IMMEDIAT') || this.$route.query.dc) {
    
                        routeName = 'cardFormIframe'
                    }
                    else if (this.order.is_invoice_needed && (this.order.user.active_identity_proofs_count > 0 || this.$route.query.f == 1)) {

                        routeName = 'invoiceIframe'
                    }
                    else if (this.order.user.active_identity_proofs_count < 1) {
    
                        routeName = 'kycIframe'
                    }
                    else {
    
                        routeName = 'transferIframe'
                    }
                }
                else if (this.order.order_status_id === 2) {

                    if (this.order.is_transfer_signed) {

                        routeName = 'validatedIframe'
                    }
                }
            }

            return routeName
        },
        getRouteName() {

            let routeName = ''

            if (this.$route.name == 'ibanForm' && this.order.payment_type == 'cash' && [2,3,4,5,6,7].includes(this.order.order_status_id)) {
    
                routeName = 'ibanForm'
            }
            else if (this.$route.name == 'orderKyc' && this.$route.query.k == 1) {

                routeName = 'orderKyc'
            }
            else if (this.order.order_status_id == 9) {

                if (this.order.refused_counter_offer != undefined)
                    routeName = 'orderCounterOfferRefused'
                else
                    routeName = 'orderRefused'
            }
            else if (this.order.order_status_id == 8 ) {

                if (this.$route.query.dc === 1 || this.$route.query.status != null || this.$route.query.depositId != null)
                    routeName = 'mangoCardForm'
                else
                    routeName = 'orderCouponsType'
            }
            else if (this.order.order_status_id == 1) {

                if (this.order.coupon_type == 'IMMEDIAT' && this.order.payment_reservation === null && (this.order.payment_type === 'coupon' || this.order.brand_campaign != undefined)) {

                    routeName = 'mangoCardForm'
                }
                else if (this.order.is_invoice_needed && (this.order.user.active_identity_proofs_count > 0 || this.$route.query.f == 1)) {

                    routeName = 'orderInvoice'
                }
                else if (this.order.user.active_identity_proofs_count > 0 || this.$route.query.f == 1) {

                    routeName = 'orderTransfer'
                }
                else {

                    routeName = 'orderKyc'
                } 
            }
            else if (this.order.order_status_id == 2 && this.$route.query.k == 1) {

                routeName = 'orderKyc'
            }
            else if (this.order.order_status_id == 2) {

                if (this.$route.name == 'orderConfirm' && this.$route.query.f == 1) {

                    routeName = 'orderConfirm'
                }
                else {

                    routeName = 'orderDelivery'
                }
            }
            else if (this.order.order_status_id == 3) {

                routeName = 'orderSent'
            }
            else if (this.order.order_status_id == 4) {

                routeName = 'orderToValidate'
            }
            else if (this.order.order_status_id == 5) {

                if (this.order.accepted_counter_offer != undefined)
                    routeName = 'orderCounterOfferAccepted'
                else
                    routeName = 'orderAccepted'
            }
            else if (this.order.order_status_id == 6) {

                routeName = 'orderCounterOffer'
            }
            else if (this.order.order_status_id == 20) {

                routeName = 'orderExpired'
            }
            else if (this.order.order_status_id == 7) {

                routeName = 'orderStandBy'
            }
            else if (this.order.order_status_id == 21 && this.order.is_transfer_signed === 0) {

                routeName = 'orderTransfer'
            }
            else {

                routeName = 'orderConfirm'
            }

            return routeName
        }
    },
    computed: {

        isOrderLoaded() {

            return this.order != null
        },
        orderId() {

            return this.$route.params.id
        },
        orderPrice() {

            if (!this.isOrderLoaded) return null

            return this.order.price + this.order.contribution_amount
        },
        isInIframe() {
            
            try {
                
                return window.self !== window.top
            } 
            catch (e) {
                
                return true
            }
        },
    },
    watch: {
        isOrderLoaded() {

            let routeName = ''
            
            if (this.order.basket_type === 'iframe' && this.isInIframe) {
                
                routeName = this.getIframeRouteName()
            }
            else {

                routeName = this.getRouteName()
            }

            if (routeName != '' && routeName != this.$route.name) {

                this.$router.push({name: routeName, query: this.$route.query})
            }
        }
    }
}