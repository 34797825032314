<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'
import orderService from '@/services/order'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'

export default {

    components: {BasketLayout, SidePanelHeader, Btn}, 
    mixins: [orderMixin, merchantMixin],
    data() {
        return {
            
        }
    },
    methods: {
        async cancelOrder(action) {

            if (action) {

                let response = await orderService.cancelOrderById(this.orderId)

                if (response.data === 'iframe') {

                    window.parent.postMessage({type: 'basket_cancel', publicId: this.orderId}, '*')
                }

            }
        },
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('Order Cancel', 'Iframe Order', {'Order ID': this.orderId})
        }
    }
}
</script>
<template>
    <basket-layout>
        <div class="iframe-content" v-if="isOrderLoaded">
            <side-panel-header :title="''" />
            <div id="not-found-section">
                <div id="not-found-content">
                    <img id="content-header-img" src="/assets/img/icon-info-cancel.svg" alt="">
                    <h2>Êtes-vous sûr de vouloir supprimer votre offre de reprise ?</h2>
                    <div id="btns-wrapper" >
                        <btn @click="cancelOrder(true)" :text="'Oui'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" /> 
                        <btn @click="cancelOrder(false)" :text="'Annuler'" :color="'black'" :backgroundColor="`var(--iframe-color-white)`" /> 
                    </div>
                </div>
            </div>
        </div>
    </basket-layout>
</template>
<style scoped>
#content-header-img {

    margin-bottom: 20px;
}

#btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin:30px 0px;
    width: 100%;
}

#not-found-section, #not-found-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#not-found-section{
    height: calc(100vh - 100px);
    overflow-y: auto;
    padding:0 32px 32px 32px;
}
</style>

