<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/CounteredOffer.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'
import ReinsuranceMessages from '../../components/basket/ReinsuranceMessages.vue'
import MerchantLink from '@/components/basket/MerchantLink.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption, ReinsuranceMessages, MerchantLink}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$tracking.pageView('counter offer accepted', 'order', {'Order ID': this.orderId})
    },
    computed: {
        isStatusAccepted() {

            return this.isOrderLoaded && this.order.order_status_id == 5 && this.order.accepted_counter_offer != undefined ? true : false
        }
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isStatusAccepted">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8 mb-4">
                    <div class="col-11">
                        <h3>Félicitations !</h3>
                        <p v-if="order.coupon_type == 'POSTPONED' && order.payment_type == 'coupon'">
                            Vous avez accepté la contre-offre de reprise de votre produit.<br/>
                            Vous allez bientôt recevoir <span class="text-success">votre {{order.merchant.coupon_name}} {{order.merchant.coupon_platform_name}}</span>.
                        </p>
                        <p v-if="order.coupon_type == 'IMMEDIAT' && order.payment_type == 'coupon'">
                            Vous avez accepté la contre-offre de reprise de votre produit.<br/>
                            Votre {{order.merchant.coupon_name}} est déjà disponible dans votre espace client.
                        </p>
                        <p v-if="order.payment_type == 'cash'">
                            Vous avez accepté la contre-offre de reprise de votre produit.<br/>
                            Votre allez recevoir votre virement sous 10 jours.
                        </p>
                        <p v-if="order.coupon_type == 'IMMEDIAT'">
                            Conformément à nos CGU vous allez être débité de {{(order.accepted_counter_offer.from_price - order.accepted_counter_offer.to_price) | currencyInteger }}
                        </p>
                        <merchant-link :merchant="merchant" />
                    </div>
                    <reinsurance-messages class="d-none d-md-block" v-if="isMerchantLoaded && Object.keys($t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.COUNTER_OFFER_ACCEPTED')).length > 0" :messages="$t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.COUNTER_OFFER_ACCEPTED')" />
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :counter="order.accepted_counter_offer"/>
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption v-if="merchant" :hasImmediat="merchant.can_have_immediat_coupon" />
                </div>
            </div>
        </div>
    </basket-layout>
</template>
