<script type="text/ecmascript-6">
export default {
    
    props: {
        'title': {
            type: String,
            default: 'Mon produit'
        },
        'backBtnDisplayed': {
            type: Boolean,
            default: false
        },
        'closeFunction': {
            type: Function,
            default: () => { window.parent.postMessage({type: 'close'}, '*') }
        }
    },
    methods: {
        backToPreviousPage() {

            this.$router.push({name: 'SearchFormIframe'})
        }
    }
}
</script>
<template>
    <div id="header">
        <div id="header-left">
            <span @click="backToPreviousPage" id="btn-back" v-if="backBtnDisplayed"><img id="btn-back-arrow" src="/assets/img/left-arrow.png" alt=""></span>
            <h2  id="header-title">{{ title }}</h2>
        </div>
        <img @click="closeFunction" id="header-right-btn" src="/assets/img/cross.svg" alt="">
    </div>
</template>

<style scoped>

* {
    font-weight: 500;
    color: var(--iframe-font-color);
    margin: 0;
    padding: 0;
    font-family:var(--iframe-font-family);

}

#header {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    overflow: hidden;
}

#header-left {
    display: flex;
    align-items: center;
    gap: 10px;

}
#header-right-btn {
    width: 15px;
    cursor: pointer;
    z-index: 10;
}

#btn-back {
    width: 30px;
    height: 30px;
    border: 1px solid rgb(116, 116, 116);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    margin-right: 5px;
}

#btn-back-arrow{
    transform: rotate(-180deg);
    width: 20px;
    height: 20px;
    color:rgb(116, 116, 116);
}

h2 {
    font-size: var(--iframe-font-size-h2);
    font-weight: var(--iframe-font-weight-bold);
}

</style>