<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import ImportFiles from "@/components/basket/ImportFiles.vue";
import categoryService from '@/services/category.js'
import brandService from '@/services/brand.js'
import listingService from '@/services/listing'
import merchantMixin from '@/mixins/merchant'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import StandardModal from '@/components/StandardModal.vue'
import Conditions from '@/components/basket/Conditions.vue'
import MerchantLink from '@/components/basket/MerchantLink.vue'

export default {

    mixins: [merchantMixin],
    components: {vSelect, BasketLayout, ImportFiles, StandardModal, Conditions, MerchantLink}, 
    data() {

        return {

            isLoading: false,
            listingData: {

                category:{
                    id:null
                },
                brand:{
                    id:null
                },
                model:'',
                year:'',
                condition:'',
                details:'',
                email:null,
                firstName:'',
                lastName:'',
                importedFiles:[],
                productType: '',
                conditions: null,
                merchantUserId: null
            },
            adStep:1,
            categories:[],
            conditionsFilter: this.$t('BASKET_CONDITION_LABEL.OTHER'),
            brands : [],
            inputDisabled: false,
            email: '',
            emailMessage: null,
            inputAdditionalDisabled: false,
            listingStatus:'',
            listingUpdated: null,
            errorMessage: null,
            errorMissingFieldsMessage:null,
            helperModal: null,
            areConditionsOK: false,
            storageUsed: null,
            refusedReason: null,
            listingPhotoClicked:null,
            displayConditions:true,
            currentStep:null,
            endOfMessage:'',
        }
    },
    created() {
        
        this.$store.commit('setSource', this.$route.query.source)
        this.$store.commit('setMerchantUserId', this.$route.query.umid)

        if (this.isMerchantLoaded) {

            this.init()
        }
        else {

            this.loadMerchantBySubdomain()
        }

        this.$tracking.pageView('listing', 'listing', {'Listing ID': this.listingId, 'Merchant User ID': this.merchantUserId})
        this.setCurrentStep(20)
    },
    methods: {

        setCurrentStep(val) {

            this.$store.commit('setCurrentStep', val)
        },
        goToSearchForm() {

            this.$router.push({name: 'searchForm'})
        },
        async createFromListing() {

            this.isLoading = true
            let token = process.env.VUE_APP_ENVIRONMENT === "local" ? "1" : await this.recaptcha()
            
            this.updateDetails()

            try {

                this.isLoading = true
                let response = await listingService.createListing(this.listingData, this.merchant.id, token, this.merchantUserId, this.source)
                this.listingUpdated = response.data
                this.$router.push({path: '/listing/' + this.listingUpdated.public_id, params: {listingId: this.listingUpdated.public_id}})
            }
            catch (error) {

                this.errorMessage = 'Une erreur est survenue, veuillez contacter notre service client pour plus d\'informations'

                this.$tracking.logEvent('Create listing from listing page error', {'Page Name': 'listing', 'Page Category': 'listing', 'Error': 'Create listing error > ' + error, 'Merchant User ID': this.merchantUserId})
            }

            this.isLoading = false
        },
        async updateFromListing() {

            this.isLoading = true
            let token = process.env.VUE_APP_ENVIRONMENT === "local" ? "1" : await this.recaptcha()
            this.updateDetails()

            try {

                this.isLoading = true
                let response = await listingService.updateListing(this.listingData, this.listingId, token, false)
                this.listingUpdated = response.data
            }
            catch (error) {

                if (error.response.status == 422) {

                    this.errorMessage = 'Une erreur est survenue, veuillez contacter notre service client pour plus d\'informations'
                }
                this.$tracking.logEvent('Update listing from listing page error', {'Page Name': 'listing', 'Page Category': 'listing', 'Error': 'Update listing error > ' + error, 'Merchant User ID': this.merchantUserId})
            }

            this.isLoading = false
        },
        async getListingById(listingId) {

            this.isLoading = true

            try {

                let response = await listingService.get(listingId, true)
                let data = response.data
    
                this.listingData.brand = data.brand
                this.listingData.category = data.category
                this.listingData.model = data.model
                this.listingData.details = data.details
                this.listingData.productType = data.product_type
                this.listingData.conditions = data.conditions
                this.listingData.merchantUserId = data.merchant_user_id
    
                this.listingStatus = data.status
                if (data.status != 'created') {
    
                    this.listingData.condition = data.product_condition_id
                    this.listingData.year = data.year
                    this.listingData.details = data.details
                    this.listingData.price = data.price
                    this.listingData.contributionAmount = data.contribution_amount
    
                    this.inputAdditionalDisabled = true
                }

                if (data.status === 'refused') {

                    this.refusedReason = data.refused_reason
                }
    
                this.inputDisabled = true
            }
            catch (error) {

                if (error.response.status == 401) {

                    this.$router.push({path: '/order/' + error.response.data.order_public_id})
                }

                if (error.response.status == 404) {

                    this.$router.push({path: '/searchForm'})
                }
            }
            this.isLoading = false
        },
        async loadCategories() {

            let response = await categoryService.list(this.merchant.id)
            this.categories = this.sortCategories(response.data)

            if (this.$route.query.cid != undefined) {

                this.listingData.category.id = this.$route.query.cid
            }

            if (this.$route.query.brand != undefined) {
                            
                let brandName = this.$route.query.brand.charAt(0) + this.$route.query.brand.slice(1).toLowerCase();
                this.listingData.brand.id = this.brands.filter(brand => brand.name == brandName)[0].id
            }

            if (this.$route.query.model != undefined) {

                this.listingData.model = this.$route.query.model
            }

            if (this.$route.query.details != undefined) {

                this.listingData.details = this.$route.query.details
            }
        },
        getCategory(categoryId) {

            return this.categories.find(element => element.id == categoryId)
        },
        async loadBrands() {

            let response = await brandService.list()
            this.brands = response.data
        },
        updateFiles(payload) {

            this.listingData.importedFiles = payload.files
        },
        updateStorageData(storage) {

            this.storageUsed = storage
        },
        nextStep() {

            this.errorMissingFieldsMessage = null
            this.errorMessage = null

            if (!this.listingSteps()) {

                this.$tracking.logEvent('Listing next step', {'Page Name': 'listing', 'Page Category': 'listing', 'Current Step': this.adStep, 'Merchant User ID': this.merchantUserId})
    
                if (this.adStep === 3) {
    
                    if (this.listingId != null) {
                        
                        this.updateFromListing()
                    }
                    else {
                        
                        this.createFromListing()
                    }
                }
    
                this.adStep++
        
                if (this.adStep === 4) {
    
                    this.getListing()
                }
            }
        },
        async recaptcha() {

            await this.$recaptchaLoaded()

            return await this.$recaptcha('createListing')
        },
        toAcceptOfferVue() {

            this.$router.push({name:'listingAcceptOffer', params: {id:this.$route.params.listingId}})
        },
        async getListing() {

            if (this.listingId != null) {

                await this.getListingById(this.listingId)

                let transformedMerchantUserId = this.transformMerchantUserId(this.listingData.merchantUserId, this.listingData.merchant_id)
                this.$store.commit('setMerchantUserId', transformedMerchantUserId)
            }
        },
        styleLabel(label) {

            return '<strong>' + label.replace('-', '</strong> - ')
        },
        async getConditions(categoryId, brandId) {

            let response =  await listingService.getConditions(categoryId, brandId)

            return response.data
        },
        updateCondition(val) {

            let conditionsLength = Object.values(this.listingData.conditions).length
            let areConditionsOk = true

            for (let i = 1; i <= conditionsLength; i++) {

                if (this.listingData.conditions[i].name == val.name) {

                    this.listingData.conditions[i]['val'] = val.val
                    this.listingData.conditions[i]['title'] = val.title
                }

                if (this.listingData.conditions[i].answer != undefined || this.listingData.conditions[i].name == 'IS_WORKING' || this.listingData.conditions[i].name == 'IS_COMPLETE') {

                    if (this.listingData.conditions[i].val == undefined) {

                        areConditionsOk = false
                    }    
                }              
            }

            this.areConditionsOK = areConditionsOk
        },
        sortCategories(categories) {

            return categories.sort((a, b) => { 
            
                if (a.name === 'Autres') {

                    return 1
                }

                if (b.name === 'Autres') {

                    return -1
                }

                if (a.name < b.name) {

                    return -1
                }
                
                if (a.name > b.name) {

                    return 1
                }

                return 0
            })
        },
        goBack() {

            this.$router.go(-1)
        },
        init() {

            this.setMerchantUserId(this.merchant, this.merchantUserId)

            if (this.listingId != null) {

                this.getListing()
                if (this.adStep > 1) {

                    this.displayConditions = false
                }
            }
            else {

                if (this.merchantUserId) {

                    this.loadBrands()
                    this.loadCategories()
                }
            }
       
        },
        listingSteps() {

            let isFieldMissing = false
            this.endOfMessage = ''
            this.errorMissingFieldsMessage = null
            this.currentStep = null
            let missingField = ''

            let checkValue = [
                {
                    step:1,
                    items: [
                        {

                            value: this.conditionCheck,
                            labelError: "l'état",
                        },
                        {

                            value: this.brandCheck,
                            labelError: 'la marque',
                        },
                        {

                            value: this.yearCheck,
                            labelError: 'l\'année',
                        },
                        {

                            value: this.detailsCheck,
                            labelError: "les détails",
                        },
                        {

                            value: this.modelCheck,
                            labelError: "le modèle",
                        },
                        {

                            value: this.areConditionsOK,
                            labelError: "les réponses aux questions",
                        },
                    ],
                    endOfErrorMessage: ' de votre produit.'
                },
                {
                    step:2,
                    items: [
                        {
                            value: this.photosCheck,
                            labelError: "au moins deux photos",
                        },
                    ],
                    endOfErrorMessage: ' de votre produit.'
                },
                {
                    step:3,
                    items: [
                        {
                            value: this.emailCheck,
                            labelError: "votre email",
                        },
                        {
                            value: this.firstNameCheck,
                            labelError: "votre prénom",
                        },
                        {
                            value: this.lastNameCheck,
                            labelError: "votre nom",
                        },
                    ],
                    endOfErrorMessage: ''
                }
            ]

            checkValue.forEach(element => {
                
                if (element.step === this.adStep) {

                    this.currentStep =  element
                }
            });

            missingField = ''

            isFieldMissing = false

            this.currentStep.items.forEach(element => {

                if (!element.value) {

                   if (isFieldMissing) {

                        missingField += ', '
                   }     

                    missingField += element.labelError

                    isFieldMissing = true
                }
            });

            missingField += this.currentStep.endOfErrorMessage

            if (isFieldMissing) {

                this.errorMissingFieldsMessage = 'Afin de continuer, veuillez renseigner '+ missingField

                return isFieldMissing
            }

            return false
        },
        updateDetails() {

            let conditionsLength = Object.values(this.listingData.conditions).length
            
            for (let i = 1; i <= conditionsLength; i++) {

                let element = this.listingData.conditions[i]
                if (element.title != undefined) {

                    let answer = 'Non'
                    if (element.val) {

                        answer = 'Oui'
                    }

                    let title = element.title.replace('<strong>', '')
                    title = title.replace('</strong>', '')

                    this.listingData.details += title + ' : ' + answer + ' \n'
                }
            }
        } 
    },
    computed: {

        yearCheck() {

            return this.listingData.year.length < 4 ? false : true
        },
        detailsCheck() {

            return this.listingData.details.length < 10 ? false : true
        },
        conditionCheck() {

            return this.listingData.condition.length === 0 ? false : true
        },
        brandCheck() {

            return this.listingData.brand.id === null ? false : true
        },
        modelCheck() {

            return this.listingData.model === '' ? false : true
        },
        photosCheck() {

            return this.listingData.importedFiles.length >= 2 ? true : false
        },
        emailCheck() {

            return this.listingData.email === null ? false : true
        },
        firstNameCheck() {

            return this.listingData.firstName === '' ? false : true
        },
        lastNameCheck() {

            return this.listingData.lastName === '' ? false : true
        },
        listingId() {

            return this.$route.params.listingId != null ? this.$route.params.listingId : null

        },
        listingBrandId() {

            return this.listingData.brand.id
        },
        listingCategoryId() {

            return this.listingData.category.id
        },
        selectedContract() {

            return this.$store.getters.selectedContract
        },
        source() {

            return this.$store.getters.source
        },
        merchantUserId() {

            return this.$store.getters.merchantUserId
        },
        brandsSorted() {

            return this.brands.slice(0).sort(function (a, b) {

                if (a.name == 'Autre') return 1

                if (b.name == 'Autre') return -1

                return a.name > b.name
            })
        },
        isFilesSizeOk() {

            return this.storageUsed < 8350000 ? false : true
        }
    },
    watch: {

        email() {

            this.listingData.email = null
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(this.email)) {
                
                this.listingData.email = this.email
                this.emailMessage = null
            } 
            else {
                
                this.listingData.email = null
                this.emailMessage = 'Veuillez rentrer un email valide'
            }
        },
        listingUpdated() {

            this.getListing()
        },
        async listingBrandId() {

            if (this.listingBrandId != undefined && this.listingBrandId != '' && this.listingCategoryId != undefined && this.listingCategoryId != '') {

                let conditions = await this.getConditions(this.listingCategoryId, this.listingBrandId)
                this.listingData.conditions = conditions.conditions
                this.listingData.productType = conditions.type
            }
        },
        async listingCategoryId() {

            if (this.listingBrandId != undefined && this.listingBrandId != '' && this.listingCategoryId != undefined && this.listingCategoryId != '') {

                let conditions = await this.getConditions(this.listingCategoryId, this.listingBrandId)
                this.listingData.conditions = conditions.conditions
                this.listingData.productType = conditions.type
            }
        },
        isMerchantLoaded(val) {

            if (val == true) {
            
                this.init()
            }
        }
    }
}
</script>
<template>
    <basket-layout>
        <div v-if="merchantUserId && adStep <= 4" class="row d-flex flex-lg-row justify-content-between">
            <div v-if="listingStatus === 'to_validate' || listingStatus == 'to_validate_greendid'"  class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column align-items-center right-column">
                <h3>Votre demande est entre de bonnes mains !</h3>
                <p class="text-center">
                    Nous avons bien enregistré les informations de votre produit.
                    <br/>Notre équipe évalue soigneusement chaque détail.
                    <br/>Vous recevrez notre offre de reprise personnalisée par e-mail d'ici 2 jours ouvrés.*
                </p>
                <merchant-link :merchant="merchant" />
            </div>
            <div v-if="listingStatus === 'accepted'"  class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column justify-content-center right-column">
                <h3>Votre annonce a été acceptée !</h3>
                <p>Vous avez 48h pour accepter l'offre.</p>
                <p>Pour que la reprise soit validée vous allez devoir fournir une pièce d'identité, signer un bon de cession puis expédier votre produit.</p>
                <div class="d-flex flex-row justify-content-between m-3">
                    <button @click="toAcceptOfferVue" class="btn btn-primary">
                        Accepter l'offre de {{ (listingData.price / 100 + listingData.contributionAmount / 100).toFixed(2) }} €
                    </button>
                </div>
            </div>
            <div v-if="listingStatus === 'refused'" class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column right-column">
                <h3 class="align-self-center">Votre annonce a été refusée</h3>
                <p><strong>Raison du refus : </strong>"<i>{{this.refusedReason}}"</i></p>
                <p>Vous pouvez proposer un autre produit</p>
                <button @click="goToSearchForm()" class="btn btn-primary">Créer une autre annonce</button>
            </div>
            <div v-if="listingStatus === '' && isLoading" class="shadow-lg mt-3 mt-md-5 p-4 col-12 col-lg-8 border bg-white rounded d-flex flex-column align-items-center right-column">
                Veuillez patienter pendant la création de votre annonce
                <span class="spinner-border spinner-border-lg mr-2 mt-4" role="status" aria-hidden="true"></span>
            </div>
            <div v-if="errorMessage != null" class="alert alert-danger mt-3 mt-md-5" role="alert">
                {{ errorMessage }}
            </div>
            <h3 v-if="errorMessage != null" class="mt-3 mt-md-5">
                Votre Annonce 
                <span v-if="(listingStatus === '' || listingStatus === 'created') && adStep < 4">
                    (Etape {{ adStep }}/3) 
                </span>
                <span v-if="isLoading" class="spinner-border spinner-border-lg mr-2" role="status" aria-hidden="true"></span>
            </h3>
            <div v-if="adStep == 1" id="product-infos" class="shadow-lg mt-4 border bg-white rounded col-12 col-lg-8">
                <div class="p-3">
                    <legend>Informations du produit</legend>
                    <legend class="text-muted fs-6">
                        Tous les champs sont obligatoires afin de passer à l'étape suivante.
                        <br/>
                        <span style="font-size:0.6rem">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</span>
                    </legend>
                    <div class="mb-3">
                        <label class="form-label">Catégorie</label>
                        <select v-if="!listingStatus" class="form-select" :disabled="inputDisabled" v-model="listingData.category.id" >
                           
                            <option value="" disabled></option>
                            <option :id="category.id" :key="category.id" :value="category.id" v-for="category in categories" placeholder="Catégorie">
                                {{ category.name }}
                            </option>

                        </select>
                        <input v-else class="form-select" type="text" v-model="listingData.category.name" :disabled="inputDisabled">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Marque</label>
                        <v-select v-if="!listingStatus" :disabled="inputDisabled" v-model="listingData.brand.id" :options="brandsSorted" label="name" :reduce="brand => brand.id">
                            <div slot="no-options">Pas de marque trouvée</div>
                        </v-select>  
                        <input v-else class="form-select" type="text" v-model="listingData.brand.name" :disabled="inputDisabled">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Etat du produit</label>
                        <div class="row">
                            <div class="col-11">
                                <select class="form-select border" :disabled="inputAdditionalDisabled" v-model="listingData.condition">
                                    <option value="" disabled></option>
                                    <option :key="index" :value="parseInt(index)" v-for="(condition, index) in conditionsFilter" placeholder="Condition" v-html="condition">
                                    </option>
                                </select>
                            </div>
                            <div class="col-1 d-flex align-items-center justify-content-center">
                                <i @click="helperModal = 'CONDITION'" style="cursor: pointer;" class="bi bi-question-circle text-success font-size-1rem"></i>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Modèle</label>
                        <input class="form-control" :disabled="inputDisabled" v-model="listingData.model" type="text">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Année d'achat</label>
                        <input class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.year" type="number" min="2000" max="2099" maxlength="4">
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Détails <small class="text-muted">Indiquez tout ce qui pourra aider à l'estimation</small></label>
                        <textarea class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.details" rows="6" type="textarea" ></textarea>
                        <div v-if="!listingStatus" class="form-text">(10 caractères minimum)</div>
                    </div>
                    <conditions v-if="displayConditions" @updateCondition="updateCondition" :conditions="this.listingData.conditions" :productType="this.listingData.productType" :listingMode="true" />
                </div>
            </div>


            <div v-if="adStep == 2" id="product-photos" class="shadow-lg mt-4 border bg-white rounded col-12 col-lg-8">
                <div class="p-3">
                    <legend>
                        Photos du produit
                        <small style="font-size:0.8rem">(min 2, max 4, 2 Mo max par fichiers, 8 Mo en tout)</small>
                    </legend>
                    <p>
                        Merci de charger <strong>au moins 2 photos</strong> de votre produit afin de faciliter son évaluation.
                        <br/>
                        <br/>
                        Si vous disposez de la facture d'achat du produit, merci de la charger parmis les photos afin de mieux valoriser le produit.
                    </p>
                    <div v-if="adStep >= 2 && adStep < 4" class="mt-3 mb-3">
                        <import-files @listingPhotoClicked="listingPhotoClicked = $event" :adStep="adStep" @updateFiles="listingData.importedFiles = $event" :files="listingData.importedFiles" :maxNbrFiles="4" @updateStorageData="updateStorageData"></import-files>
                    </div>
                </div>
            </div>

            <div v-if="adStep == 3" id="seller" class="shadow-lg mt-4 border bg-white rounded col-12 col-lg-8">
                <div class="p-3">
                    <div v-if="adStep >= 3 && adStep < 4" class="text-muted m-4" id="personal-infos">
                        <legend>Vos coordonnées</legend>
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input class="form-control" :disabled="inputAdditionalDisabled" v-model="email" type="email">
                            <p v-if="emailMessage"> {{ emailMessage }}</p>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Prénom</label>
                            <input class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.firstName" type="text">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Nom</label>
                            <input class="form-control" :disabled="inputAdditionalDisabled" v-model="listingData.lastName" type="text">
                        </div>                              
                    </div>
                </div>
            </div>
  
            <div class="col-12 col-lg-8 mt-4" v-if="listingStatus === 'created' || (listingStatus === '' && adStep < 4)">
                <p v-if="isFilesSizeOk" class="text-danger">Vous avez dépassé la limite de 8Mo de stockage, veuillez retirer les photos trop volumineuse</p>
                <div class="row">
                    <div class="col-2">
                        <button v-if="adStep > 1" @click="adStep--" :disabled="adStep > 3" class="btn btn-secondary w-100">Retour</button>
                        <button v-if="adStep <= 1" @click="goBack()" class="btn btn-secondary w-100">Retour</button>
                    </div>
                    <div class="col-10">
                        <button :disabled="adStep > 3 || (adStep === 2 && isFilesSizeOk)" @click="nextStep" class="btn btn-primary w-100">
                            <span v-if="adStep < 3">Valider ces informations (Etape {{ adStep }}/3)</span>
                            <span v-else-if="adStep === 3">Soumettre cette annonce <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span></span>
                        </button>
                    </div>
                    <div v-if="errorMissingFieldsMessage != null" class="alert alert-danger mt-3 mt-md-5" role="alert">
                {{ errorMissingFieldsMessage }}
            </div>
                </div>
            </div>
            <standard-modal v-if="helperModal && listingData.productType != ''" :title="$t('BASKET_CONDITION.' + helperModal + '.' + listingData.productType + '.TITLE')" :closeClass="'btn-success'" :closeLabel="'Fermer'" @close="helperModal = null">
                <template v-slot:body>
                    <p v-html="$t('BASKET_CONDITION.' + helperModal + '.' + listingData.productType + '.DESCRIPTION')"></p>
                    <ul v-if="helperModal == 'CONDITION'">
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.1'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.2'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.3'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.4'))"></li>
                        <li v-html="styleLabel($t('BASKET_CONDITION_LABEL.' + listingData.productType + '.5'))"></li>
                    </ul>
                </template>
            </standard-modal>
            <standard-modal v-if="listingPhotoClicked != null" :closeLabel="'Retour'" @close="listingPhotoClicked = null">
                <template v-slot:body>
                    <div style="width:100vh;height: 100vh">
                    <div class="row p-2 d-flex">
                            <object class="col-12" :data="listingPhotoClicked"></object>
                        </div>
                    </div>
                </template>
            </standard-modal>
        </div>
    </basket-layout>
</template>

<style scoped>

.right-column {

    height: fit-content;
}

</style>