<script type="text/ecmascript-6">
export default {
    props: {
        hasImmediat: {
            type: Number,
            default: 1
        }
    }
}

</script>
<template>
    <div class="mt-2 offset-1 text-gd-faint font-size-xxs">
        <p>* Sous réserve de conformité de votre produit avec votre déclaratif</p>
        <p v-if="hasImmediat == 1">(1) Délai de 5 minutes à partir de la signature électronique validant la revente, sous réserve de choisir le bon d’achat ou carte cadeau immédiat(e). Soumis à éligibilité.</p>
    </div>
</template>

