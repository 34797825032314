<script>
import merchantMixin from '@/mixins/merchant'

import HomepageLayout from '@/layouts/HomePage.vue'

import HpCarrefourOnline from '@/components/home/CarrefourOnline.vue'

export default {
    mixins: [merchantMixin],
    components: {HomepageLayout, HpCarrefourOnline},
    methods: {

        redirect() {

            if (this.isMerchantLoaded) {

                if (this.merchant.has_homepage == false) {
    
                    this.$router.push({ name: 'searchForm' })
                }
            }
        }
    },
    created() {

        this.loadMerchantBySubdomain()
        this.redirect()
        this.$store.commit('setMerchantUserId', this.$route.query.umid)
        this.$tracking.pageView('Home Page', 'Home Page')
    },
    computed: {

        displayHPCarrefourOnline() {

            return this.isMerchantLoaded && this.merchant.has_homepage && this.merchant.id == 9884974566
        }
    },
    watch: {

        isMerchantLoaded(val) {

            if (val) {

                this.redirect()
            }
        },
    }
}
</script>

<template>
    <homepage-layout>
        <hp-carrefour-online v-if="displayHPCarrefourOnline" />
    </homepage-layout>
</template>
