import axios from 'axios'

export default {

    create(
        productId,
        merchantId,
        merchantProductId,
        merchantUserId,
        merchantOrderId,
        merchantProductImg,
        merchantOrderDate,
        merchantProductPrice,
        conditions,
        basketPublicId,
        invoiceFilename,
        brandCampaign,
        offerTemplateId,
        specsId,
        specs,
        basketType,
        paymentType,
        sourceSite,
        listingPublicId
    ) {

        var path = '/api/customer/order'
        var data = {
            productId: productId,
            merchantId: merchantId,
            merchantProductId: merchantProductId,
            merchantUserId: merchantUserId,
            merchantOrderId: merchantOrderId,
            merchantProductImg: merchantProductImg,
            merchantOrderDate: merchantOrderDate,
            merchantProductPrice: merchantProductPrice,
            conditions: conditions,
            basketPublicId: basketPublicId,
            invoiceFilename: invoiceFilename,
            brandCampaignPublicId: brandCampaign ? brandCampaign.public_id : null,
            offerTemplateId: offerTemplateId,
            specsId: specsId,
            specs: specs,
            basketType: basketType,
            paymentType: paymentType,
            sourceSite: sourceSite,
            listingPublicId: listingPublicId
        }

        return axios.post(path, data)
    },
    get(orderPublicId, withCanBeImmediat = false, withRenewPreauth = false) {

        var path = '/api/customer/order/' + orderPublicId
        if (withCanBeImmediat === true) {

            path = path + '?c=1'
        }

        if (withRenewPreauth === true) {

            let separator = path.includes('?') ? '&' : '?'
            path = path + separator + 'rp=1'
        }

        return axios.get(path);

    },
    getAll() {

        var path = '/api/customer/orders'

        return axios.get(path);
    },
    getDeliveryNote(orderPublicId, isMobile) {

        var path = '/api/customer/order/' + orderPublicId + '/delivery'

        if (isMobile) {

            path = path + '?mobile=1'
        }
        else {

            path = path + '?mobile=0'
        }

        return axios.get(path);
    },
    createTransfer(orderPublicId, phoneNumber) {

        var path = '/api/customer/order/' + orderPublicId + '/transfer'
        var data = {
            phoneNumber: phoneNumber
        }

        return axios.post(path, data);
    },
    acceptCounterOffer(orderPublicId) {

        const path = '/api/customer/orders/' + orderPublicId + '/counterOffer/accept'

        return axios.patch(path);
    },
    refuseCounterOffer(orderPublicId) {

        const path = '/api/customer/orders/' + orderPublicId + '/counterOffer/refuse'

        return axios.patch(path);
    },
    chooseType(orderPublicId, type) {

        const path = '/api/customer/orders/' + orderPublicId
        var data = {
            type: type
        }

        return axios.patch(path, data);
    },
    createImmediatePayment(orderPublicId) {

        const path = '/api/customer/orders/' + orderPublicId + '/immediatePayment'

        return axios.post(path);
    },
    cancelOrderById(orderPublicId) {

        const path = '/api/customer/order/' + orderPublicId + '/cancel'

        return axios.patch(path);
    },
    uploadInvoice(orderPublicId, invoice) {

        var formData = new FormData();

        const path = '/api/customer/order/' + orderPublicId + '/invoice'

        formData.append('invoice', invoice)

        return axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data' }})
    },
    generateCoupon(orderPublicId) {

        const path = 'api/customer/order/' + orderPublicId + '/coupon'

        return axios.get(path);
    }
}
