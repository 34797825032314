<script type="text/ecmascript-6">

export default {

    props: {
        merchant: {
            type: Object
        }
    },
    methods: {

        click() {

            if (this.merchantButtonDisplayType == 'iframe') {

                document.getElementsByTagName('body')[0].dispatchEvent(new CustomEvent('fox', {detail: {action: 'close-IEW'}}))
            }
            else if (this.merchantButtonDisplayType == 'link') {

                window.location = this.merchant.orders_history_url
            }
            else if (this.merchantButtonDisplayType == 'greendid') {

                this.$router.push({name: 'searchForm'})
            }
        }
    },
    computed: {

        merchantButtonDisplayType() {

            if (this.merchant == undefined) return 'none'

            if (this.merchant.orders_history_url == 'iframe') {

                return 'iframe'
            }

            if (this.merchant.orders_history_url == 'greendid') {

                return 'greendid'
            }

            return 'link'
        }
    }
}

</script>
<template>
    <button v-if="merchantButtonDisplayType != 'none' && merchantButtonDisplayType != 'greendid'" class="btn btn-info" role="button" @click="click()">Aller sur mon espace {{ merchant.name }}</button>
    <button v-else-if="merchantButtonDisplayType == 'greendid'" class="btn btn-info" role="button" @click="click()">Faire une nouvelle reprise</button>
</template>
