<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import merchantMixin from '@/mixins/merchant'
import orderMixin from '../../mixins/order'
import FaqMixin from '@/mixins/faq'
import validator from 'validator';
import userServices from '@/services/user';

export default {

    mixins: [merchantMixin, FaqMixin, orderMixin],
    components: {BasketLayout}, 
    data() {

        return {

            iban: '',
            error: null,
            isLoading: false
        }
    },
    created() {
        
        this.$tracking.pageView('IBAN Form', 'IBAN Form')
        this.$store.commit('setCurrentStep', 50)
    },
    methods: {
        async postCashInfos() {

            await userServices.postIban(this.iban)
            this.$tracking.logEvent("Click Validate IBAN", {'Page Name': 'IBAN Form', 'Placeholder':'IBAN Button'})
            this.$router.push({name: 'orderConfirm', params: {id: this.orderId}, query: {f: 1}})            
        },
        checkIban() {

            return validator.isIBAN(this.iban)
        },
        async clickSubmit() {

            this.isLoading = true
            if (this.displayAddress) {

                let response = await this.$refs.addressForm.createAddress()
                if (response !== true) {

                    this.error = response
                    this.isLoading = false

                    return
                }
            }
            
            this.postCashInfos()

            this.isLoading = false
        },
        addressError(error) {

            this.error = error
            this.isLoading = false
        }
    },
    computed: {

        isAlreadyStocked() {

            return this.order != undefined && ['submitted', 'validated'].includes(this.order.user.mango_status)
        },
        enableSubmitButton() {

            if (this.checkIban() && !this.isAlreadyStocked) {

                return true
            }

            return false
        }
    }
}
</script>

<template>
    <basket-layout>
        <div class="row mt-3 mt-md-5 d-md-flex">
            <div class="m-3 m-md-0 col-md-10 ms-md-5">
                <div class="col-md-6">
                    <h3>Renseignez vos informations de facturation</h3>
                    <div class="d-flex flex-row align-items-end justify-content-between">
                        <p>Afin de recevoir le virement sur votre compte, veuillez renseigner votre IBAN.</p>
                    </div>
                </div>
                <div class="form-group col-md-8">
                    <div v-if="error" class="alert alert-danger" v-html="error"></div>
                    <div class="form-group mt-3 mb-1">
                        <input class="form-control" v-model="iban" type="text" placeholder="IBAN">
                    </div>
                    <button @click="clickSubmit" type="button" :disabled="!enableSubmitButton" class="btn btn-success mt-3">
                        <span v-if="isAlreadyStocked">Votre IBAN est en cours de vérification</span>
                        <span v-else>Je valide mes informations</span>
                        <span v-if="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </button>
                    <p v-if="!checkIban() && iban != ''"><small class="text-danger">L'IBAN renseigné n'est pas valide</small></p>
                </div>
            </div>
        </div>
        <div class="card mt-4 ms-md-5">
            <div class="card-body row pb-2">
                <div class="col-1">
                    <i class="bi bi-question-circle text-success font-size-2rem"></i>
                </div>
                <div class="col ps-0">
                    <ul>
                        <li>L'IBAN est l'identifiant de votre compte bancaire</li>
                        <li>Il est indiqué sur votre RIB et est nécessaire pour percevoir un virement.</li>
                        <li>Votre adresse postale est aussi nécessaire pour percevoir un virement.</li>
                    </ul>
                </div>
            </div>
        </div>
    </basket-layout>
</template>