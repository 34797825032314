<script type="text/ecmascript-6">
    import BasketHeader from '../components/basket/HeaderV2.vue'
    import BasketFooter from '../components/basket/FooterV2.vue'
    import BasketReinsurance from '../components/ReinsuranceV2.vue'
    import merchantMixin from '@/mixins/merchant'

    export default {
        mixins: [merchantMixin],
        components: {BasketHeader, BasketFooter, BasketReinsurance},
        metaInfo() {

            return {
         
                title: this.merchantTitle,
                meta: [
                    { name: 'description', content:  this.merchantTitle + " | Revendez vos produits hight-tech en 2 minutes et au meilleur prix" }
                ]
            }
        }
    }
</script>
<template>
    <section>
        <basket-header :withBreadCrumbs="true" />
        <slot/>
        <basket-reinsurance />
        <basket-footer />
    </section>
</template>
