<script type="text/ecmascript-6">

import BasketLayout from '../../layouts/iframe/Basket.vue'
import SidePanelHeader from '../../components/iframe/SidePanelHeader.vue';
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import BtnIframe from '@/components/iframe/Button.vue'
import validator from 'validator';
import userServices from '@/services/user';

export default {

    components: {BasketLayout, SidePanelHeader, BtnIframe}, 
    mixins: [merchantMixin, orderMixin],
    data() {
        return {
            iban: ''
        }
    },
    methods: {
        async sendIban() {
            
            await userServices.postIban(this.iban)

            this.returnToPreviousStep()
        },
        returnToPreviousStep() {

            this.$router.push({name: 'validatedIframe', params: {orderId: this.order.public_id}})
        }
    },
    computed: {
        checkIban() {

            return validator.isIBAN(this.iban)
        }
    },
    watch: {

        isOrderLoaded() {

            this.$tracking.pageView('IBAN Form', 'Iframe Order', {'Order ID': this.order.public_id})
        }
    }
}
</script>
<template>
    <basket-layout>
        <side-panel-header :title="'IBAN'" />
        <div class="iframe-content" v-if="merchant && order">
            <p><strong>Renseignez vos informations pour le virement bancaire.</strong></p>
            <p class="mt-4">Afin de recevoir le montant de votre reprise sur votre compte, veuillez renseigner votre IBAN.</p>
            <input class="form-control mt-4" v-model="iban" type="text" placeholder="FR00 1234 1234 1234 1234 1234">
            <p v-if="!checkIban && iban != ''"><small class="text-danger">L'IBAN renseigné n'est pas valide</small></p>
            <btn-iframe :disabled="!checkIban || iban == ''" class="btn-iframe-dark mt-4" :text="'Valider l\'IBAN'" @click="sendIban()"/>
            <btn-iframe class="btn-iframe-light mt-4" :text="'Retour'" @click="returnToPreviousStep()"/>
            <p class="mt-4 text-center"><img src="/assets/img/secure.svg" /> <small>100% securisé</small></p>
            <p class="mt-4"><small>L’IBAN est l’idendifiant de votre compte bancaire et il est indiqué sur votre RIB.</small></p>
        </div>
    </basket-layout>
</template>