<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/BasketV2.vue'
import orderMixin from '@/mixins/order'
import merchantMixin from '@/mixins/merchant'
import AcceptedOffer from '@/components/basket/AcceptedOfferV2.vue'
import ProductCard from '@/components/basket/ProductCardV2.vue'
import ServicesCard from '@/components/basket/ServicesCard.vue'
import ProductCaption from '@/components/basket/ProductCaption.vue'
import ReinsuranceMessages from '@/components/basket/ReinsuranceMessages.vue'
import MerchantLink from '@/components/basket/MerchantLink.vue'

export default {

    components: {BasketLayout, AcceptedOffer, ProductCard, ServicesCard, ProductCaption, ReinsuranceMessages, MerchantLink}, 
    mixins: [orderMixin, merchantMixin],
    created() {
      
        this.$tracking.pageView('accepted', 'order', {'Order ID': this.orderId})
    },
    computed: {
        isStatusAccepted() {

            return this.isOrderLoaded && this.order.order_status_id == 5 ? true : false
        }
    }
}
</script>
<template>
    <basket-layout :withBreadCrumbs="false">
        <div v-if="isOrderLoaded">
            <div class="row mt-3 mt-md-5">
                <div class="col-md-8">
                    <div class="col-md-11">
                        <h3>Félicitations !</h3>
                        <p>
                            La reprise de votre produit a été acceptée.<br/>
                            <span v-if="order.coupon_type == 'POSTPONED'">Vous recevrez <span class="text-success">votre {{order.merchant.coupon_name}} sous 1 heure</span> dans votre espace client.</span>
                            <span v-if="order.coupon_type == 'IMMEDIAT'">Votre {{order.merchant.coupon_name}} a déjà été crédité dans votre espace client.</span>
                        </p>
                        <merchant-link :merchant="merchant" />
                    </div>
                    <reinsurance-messages class="d-none d-md-block" v-if="isMerchantLoaded && Object.keys($t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.ACCEPTED')).length > 0" :messages="$t('REINSURANCE_MESSAGES.' + this.merchant.subdomain + '.ACCEPTED')" />
                </div>
                <div class="offset-md-0 col-md-4">
                    <div class="card shadow-lg w-100">
                        <product-card :basket="order" />
                        <accepted-offer :price="orderPrice" :merchant="order.merchant" :type="order.coupon_type" :bonus="order.brand_campaign" :paymentType="order.payment_type" />
                    </div>
                    <services-card v-if="merchant" :productType="order.product_type" :merchant="merchant" />
                    <product-caption v-if="merchant" :hasImmediat="merchant.can_have_immediat_coupon" />
                </div>
            </div>
        </div>
    </basket-layout>
</template>
