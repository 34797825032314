import axios from 'axios'

export default {

    postIban(iban) {

        let path = '/api/customer/user/iban'
        let body = {
            iban: iban 
        }

        return axios.post(path, body)
    },
    postAddress(form) {

        let path = '/api/customer/user/address'
        let body = {
            address1: form.address1,
            address2: form.address2,
            zipcode: form.zipcode, 
            city: form.city, 
            country: form.country 
        }

        return axios.post(path, body)
    }
}
