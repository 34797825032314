import axios from 'axios'

export default {

    getMerchant(merchantId) {

        var path = '/api/customer/merchant/' + merchantId

        return axios.get(path)
    },
    getMerchantBySubdomain(subdomain, domain) {

        var path = '/api/customer/merchant?s=' + subdomain 

        if (domain != null ) {

            path += '&d=' + domain
        }

        return axios.get(path)
    }
}
