<script type="text/ecmascript-6">

import BasketLayout from '@/layouts/iframe/Basket.vue'
import SidePanelHeader from '@/components/iframe/SidePanelHeader.vue';
import Btn from '@/components/iframe/Button.vue'

export default {
    components: {BasketLayout, SidePanelHeader, Btn},
    data() {
        return {
            error: ''
        }
    },
    methods: {
        async requestAccess() {

            let hasAccess = false
            console.log('requestAccess')
            try {

                await document.requestStorageAccess()
                hasAccess = true
            }
            catch (error) {

                console.log('requestStorageAccess error', error)
                hasAccess = false
            }
            console.log('hasAccess', hasAccess)
            if (hasAccess) {

                let url = this.$route.query.r
                console.log(url)
                this.$router.push(url)
            }
            else {

                this.error = 'Accés aux cookiers tiers refusé'
            }
        }
    }
}

</script>
<template>
    <basket-layout>
        <side-panel-header :title="'Accès cookie'" />
        <div>
            <p>Pour continuer, vous devez accepter l'accès aux cookies tiers.</p>
        </div>
        <btn @click="requestAccess" :text="'Autoriser l\'accès aux cookies tiers'" :color="'white'" :backgroundColor="`var(--iframe-color-black)`" /> 
        <div v-if="error" class="alert alter-danger">{{ error }}</div>
    </basket-layout>
</template>