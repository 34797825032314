<script type="text/ecmascript-6">
export default {
    props: {
        'basket': Object,
        'listing': Object,
        'bonus': {
            type: Object,
            default: null
        }
    },
    methods: {

        getTotalAmount() {

            let price = 0
            if (this.basket.price != undefined) price = this.basket.price + this.basket.contribution_amount
            else if (this.basket.product_offer != undefined && this.basket.product_offer.price != undefined) price = this.basket.product_offer.price
            else if (this.basket.offer_specs != undefined && this.basket.offer_specs.price != undefined) price = this.basket.offer_specs.price

            let total = Number(price) + Number(this.realBonus.bonus_amount / 100)
            return total.toFixed(2)
        }
    },
    computed: {

        realBonus() {

            if (this.bonus != undefined) {

                if (this.bonus.bonus_amount_type != 'euros') return null

                return this.bonus
            }

            if (this.basket.brand_campaign != undefined) {

                if (this.basket.brand_campaign.bonus_amount_type != 'euros') return null

                return this.basket.brand_campaign
            }

            return null
        },
        merchant() {

            return this.$store.getters.merchant
        }
    }
}
</script>
<template>
    <div class="card-body row no-gutters">
        <h6 class="text-success mb-0 d-flex justify-content-between">
            <strong v-if="realBonus">REPRISE</strong>
            <div v-if="basket">
                <span v-if="basket.coupon_type != undefined && basket.coupon_type == 'POSTPONED' && basket.payment_type != 'cash' && merchant.can_have_immediat_bonus"> différée</span>
                <span v-else-if="basket.coupon_type != undefined && basket.coupon_type == 'IMMEDIAT'"> immédiate<sup>(1)</sup></span> 
                <span v-if="this.realBonus" class="text-success ms-3"><strong>{{getTotalAmount()}}€</strong></span>
            </div>
        </h6>
        <div class="col-7" v-if="basket">
            <div v-if="basket.attached_to === 'product'">
                <div class="mt-3">
                    <strong>{{basket.product.brand.name}}</strong>
                    <div class="small">{{basket.product.name}}</div>
                    <div class="small">(ou produit équivalent)</div>    
                </div>
                <table v-if="basket.type === 'order_history'" class="table-borderless w-100 mt-4 font-size-xxs">
                    <tbody>
                        <tr>
                            <td class="pr-3"><strong>Prix d'achat</strong></td>
                            <td>{{basket.merchant_product_price}}</td>
                        </tr>
                        <tr>
                            <td class="pr-3"><strong>Date d'achat</strong></td>
                            <td>{{basket.merchant_order_date}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else-if="basket.attached_to === 'specs'">
                <div class="mt-3">
                    <div class="small">{{basket.offer_specs.label.replace('|', '')}}</div>
                </div>
            </div>
            <div v-else-if="basket.attached_to === 'offer_template'">
                <div class="mt-3">
                    <strong>{{basket.offer_template.displayed_brand}}</strong>
                    <div class="small">{{basket.offer_template.label.replace('|', '')}}</div>
                </div>
            </div>
            <div v-else-if="basket.attached_to === 'listing'">
                <div class="mt-3">
                    <strong>{{basket.brand_name}}</strong>
                    <div> <strong>{{basket.label}}</strong></div>
                    <div class="small">(ou produit équivalent)</div>   
                </div>
            </div>
        </div>

        <div class="col-7" v-else-if="listing">
            
            <div class="mt-3">
                <strong>{{listing.brand.name}}</strong>
                <div> <strong>{{listing.model}}</strong></div>
                <div class="small">(ou produit équivalent)</div>    
            </div>
        </div>
        
        <div class="col-5 m-auto" v-if="basket">
            <img class="img-fluid" :src="basket.merchant_product_img" />
        </div>
    </div>
</template>
