export default {

    state: {
        experiments: {
            'calibration': {

                value: null,
                populations: ['A', 'B']
            },
            'calibration2': {

                value: null,
                populations: ['A', 'B', 'C']
            },
        }
    },
    getters: {
        experimentValue: (state) => (name) => { return state.experiments[name].value },
        experiments: state => { 

            let experiments = []
            
            for (const [key, value] of Object.entries(state.experiments)) {

                experiments[key] = value.value
            }

            return experiments
        }
    },
    mutations: {
        initExperiments(state) {

            for (const [key, value] of Object.entries(state.experiments)) {
                
                if (value.value == undefined) {
                    
                    let experimentName = 'greendid_experiment_' + key
                    let experimentValue = localStorage.getItem(experimentName)
                    
                    if (experimentValue == undefined) {

                        let experimentValues = state.experiments[key].populations
                        let random = Math.floor(Math.random() * experimentValues.length)
                        experimentValue = experimentValues[random]
                        localStorage.setItem(experimentName, experimentValue)
                    }
                    
                    state.experiments[key].value = experimentValue
                }
            }
        }
    }
}
