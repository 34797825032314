<template>
  <div id="app" :class="merchantCss">
    <router-view/>
  </div>
</template>

<style>
    @import '../public/assets/css/theme.min.css';
    @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");
</style>

<script type="text/ecmascript-6">
import merchantMixin from '@/mixins/merchant.js'
export default {
    mixins: [merchantMixin],
    created() {

        this.loadCssFile()
        this.$store.commit('initExperiments')        
        this.$tracking.setExperiments(this.$store.getters.experiments)
    },
    methods: {
        loadCssFile() {
            
            const style = document.createElement('link');
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.href = '/assets/css/styles/' + this.merchantCss + '.css';
            
            document.head.appendChild(style);
        } 
    }
}
</script>
