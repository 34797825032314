<script type="text/ecmascript-6">

import mediaMixin from '@/mixins/media'
import merchantMixin from '@/mixins/merchant.js'

export default {
    mixins:[mediaMixin, merchantMixin],
    computed: {
        subdomain() {

            return window.location.hostname.split('.')[0]
        },
        isOnlyAsync() {
            
            if (this.merchant == undefined) return 0

            return this.merchant.is_only_async
        },
        isCompetitive() {
            
            if (this.merchant == undefined) return 1

            return this.merchant.is_competitive
        }
    },
    methods: {

        getReinsuranceImg(imgName) {

            if (this.merchantCss == 'fnac-reprise') {

                imgName = 'fnac-' + imgName
            }
            else if (this.merchantCss == 'bricomarche') {

                imgName = 'bricomarche-' + imgName
            }

            return this.getLocalImage(imgName)
        }
    }
}
</script>
<template>
    <div :id="merchantCss+'-reinsurance'" class="row mt-3 mt-md-5 text-success text-md-start text-center" :class="{'bg-gd-dark' : merchantCss == 'customer'}">
        <div class="offset-md-0 col-md-12 mt-md-4 mb-md-2">
            <div class="d-flex flex-md-row flex-column w-100">
                <div v-if="isOnlyAsync" class="d-flex flex-md-row flex-column justify-content-center w-100">
                    <div class="mt-3 mt-md-0 ms-0 ms-md-2 me-0 me-md-1 d-md-flex justify-content-end px-0">
                        <img class="mb-2 img-reinsurance" style="width:50px;height:50px" src="/assets/img/icons8-planete-terre-100.png" /> 
                    </div>
                    <div class="mt-2 mt-md-0 text-light">
                        <p class="small">
                            <strong>Un geste pour la planète</strong>
                            <span class="mt-1 text-gd-faint"><br>plusieurs dizaines de <br> kg de CO2 économisés</span>
                        </p>
                    </div>
                </div>
                <div v-else class="d-flex flex-md-row flex-column justify-content-center w-100">
                    <div class="mt-3 mt-md-0 me-0 me-md-1 d-md-flex justify-content-end px-0">
                        <img class="mb-2 img-reinsurance" style="width:50px;height:50px" :src="getReinsuranceImg('reinsurance-fast-resell.png')" /> 
                    </div>
                    <div class="mt-2 mt-md-0 text-light">
                        <p class="small">
                            <strong>Revente en 2 minutes</strong>
                            <span class="mt-1 text-gd-faint"><br>Pas d'annonce à créer<br>ni de prix à négocier</span>
                        </p>
                    </div>
                </div>
                <div v-if="isCompetitive" class="d-flex flex-md-row flex-column justify-content-center w-100">
                    <div class="mt-3 mt-md-0 me-0 me-md-1 d-md-flex justify-content-end px-0">
                        <img lass="mb-2" style="width:50px;height:50px" :src="getReinsuranceImg('reinsurance-best-offer.png')" />
                    </div>
                    <div class="mt-2 mt-md-0 text-light">
                        <p class="small">
                            <strong>Meilleure offre de reprise</strong>
                            <span class="mt-1 text-gd-faint"><br>Mise en concurrence<br>de dizaines de marchands</span>
                        </p>
                    </div>
                </div>
                <div v-else class="d-flex flex-md-row flex-column justify-content-center w-100">
                    <div class="mt-3 mt-md-0 me-0 me-md-1 d-md-flex justify-content-end px-0">
                        <img class="mb-2" style="width:50px;height:50px" :src="getReinsuranceImg('icons8-bouclier-64.png')" />
                    </div>
                    <div class="mt-2 mt-md-0 text-light">
                        <p class="small">
                            <strong>Reprise par un professionnel</strong>
                            <span class="mt-1 text-gd-faint"><br>transaction très simple <br>et rapide</span>
                        </p>
                    </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-center w-100">
                    <div class="mt-3 mt-md-0 me-0 me-md-1 d-md-flex justify-content-end px-0">
                        <img class="mb-2" style="width:50px;height:50px" :src="getReinsuranceImg('reinsurance-free-delivery.png')" />
                    </div>
                    <div class="mt-2 mt-md-0 text-light">
                        <p class="small">
                            <strong>Frais d'expéditions offerts</strong>
                            <span class="mt-1 text-gd-faint"><br>Etiquette pour l’expédition<br>de votre produit fournie</span>
                        </p>
                    </div>
                </div>
                <div class="d-flex flex-md-row flex-column justify-content-center w-100">
                    <div class="mt-3 mt-md-0 me-0 me-md-1 d-md-flex justify-content-end px-0">
                        <img class="mb-2" style="width:50px;height:50px" :src="getReinsuranceImg('reinsurance-clean-data.png')" />
                    </div>
                    <div class="mt-2 mt-md-0 text-light">
                        <p class="small">
                            <strong>Sérénité garantie</strong>
                            <span class="mt-1 text-gd-faint"><br>Accompagnement du début<br>à la fin de la revente</span>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
