<script type="text/ecmascript-6">

    import DashboardHeader from '../components/dashboard/Header.vue'
    import DashboardFooter from '../components/dashboard/Footer.vue'

    export default {
        components: {DashboardHeader, DashboardFooter}
    }
</script>
<template>
    <div>
        <dashboard-header />
        <div class="container mt-4">
            <slot/>
        </div>
        <dashboard-footer />
    </div>
</template>