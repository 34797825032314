<script type="text/ecmascript-6">
    import BasketHeader from '../components/basket/HeaderV2.vue'
    import BasketFooter from '../components/basket/FooterV2.vue'
    import BasketReinsurance from '../components/ReinsuranceV2.vue'
    import merchantMixin from '@/mixins/merchant'

    export default {
        mixins: [merchantMixin],
        components: {BasketHeader, BasketFooter, BasketReinsurance},
        props: {
            withBreadCrumbs: {

                type: Boolean,
                default: true
            }
        },
        metaInfo() {

            return {
         
                title: this.merchantTitle,
                meta: [
                    { name: 'description', content:  this.merchantTitle + " | Revendez vos produits hight-tech en 2 minutes et au meilleur prix" }
                ]
            }
        }
    }
</script>
<template>
    <div>
        <basket-header :withBreadCrumbs="withBreadCrumbs" />
        <div class="container">
            <slot/>
        </div>
        <basket-reinsurance />
        <basket-footer />
    </div>
</template>
