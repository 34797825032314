import Merchant from '@/mixins/merchant.js'
export default {
    mixins: [Merchant],
    computed: {

        isCustomImgFolder() {

            return this.merchantCss === 'leclercroques' ? true : false
        }
    },
    methods: {
        getMerchantAsset(merchandId, assetName) {

            return process.env.VUE_APP_ASSETS_URL + merchandId + '/' + assetName
        },
        getCategoryAsset(filename) {

            return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/' + filename
        },
        getCategoryAssetGeneric(categoryId, brand) {

            if (brand === 'APPLE') {

                return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/category-' + categoryId + '-APPLE-generic.png'
            }
            
            return process.env.VUE_APP_S3_ASSETS_URL + '/img/category/category-' + categoryId + '-generic.png'
        },
        getCguLink(subdomain) {

            if (this.merchant == undefined) return ''

            return this.merchant.white_label_domain ? process.env.VUE_APP_S3_ASSETS_URL + '/docs/' + this.getDocSuffix() + '_cgu.pdf' : process.env.VUE_APP_S3_ASSETS_URL + '/docs/greendid_cgu_' + subdomain + '.pdf'
        },
        getLegalNoticeLink(subdomain) {

            if (this.merchant == undefined) return ''

            return this.merchant.white_label_domain ? process.env.VUE_APP_S3_ASSETS_URL + '/docs/' + this.getDocSuffix() + '_mentions_legales.pdf' : process.env.VUE_APP_S3_ASSETS_URL + '/docs/greendid_mentions_legales_' + subdomain + '.pdf'
        },
        getPrivacyPolicyLink(subdomain) {

            if (this.merchant == undefined) return ''

            return this.merchant.white_label_domain ? process.env.VUE_APP_S3_ASSETS_URL + '/docs/' + this.getDocSuffix() + '_politique_de_confidentialite.pdf' : process.env.VUE_APP_S3_ASSETS_URL + '/docs/greendid_politique_de_confidentialite_' + subdomain + '.pdf'
        },
        getLocalImage(name, merchantCss = '') {

            if (this.isCustomImgFolder) {

                return '/assets/img/' + this.merchantCss + '/' + name
            }

            if (merchantCss != '') {

                return '/assets/img/' + merchantCss + '/' + name
            }

            return '/assets/img/' +  name
        },
        getDocSuffix() {

            let suffix = 'greendid'

            if (this.merchant.white_label_domain && (this.merchant.white_label_domain.includes('greendid') || this.merchant.id == 3919794380) || this.merchant.id == 7764956693) {

                suffix = this.merchant.name.toLowerCase()
                suffix = suffix.replace(' ', '-')
                suffix = suffix.replace('é', 'e')
            }
            else if (this.merchant.white_label_domain) {

                suffix = this.merchant.white_label_domain
                suffix = suffix.replace('.', '-')
            }

            return suffix
        }
    }
}
