<script type="text/ecmascript-6">
export default {
    props: ['message']
}
</script>
<template>
    <div class="alert alert-success bg-white" role="alert">
        <i class="bi bi-check-circle text-success me-2"></i> <span v-html="message"></span>
    </div>
</template>
