import axios from 'axios'

export default {

    uploadKyc(identityType, file1, file2) {

        var formData = new FormData();
        var path = '/api/customer/kyc'
        
        formData.append('identityType', identityType)
        formData.append('file1', file1)
        if (file2 != undefined) {

            formData.append('file2', file2)
        }
        
        return axios.post(path, formData, {headers: {'Content-Type': 'multipart/form-data' }})
    }
}
