<script type="text/ecmascript-6">

import faqMixin from '@/mixins/faq.js'
export default {
    props: ['messages'],
    mixins: [faqMixin],
    data() {

        return {

            questionClicked: []
        }
    },
    methods: {

        showAnswer(question) {

            if (this.questionClicked.includes(question)) {

                let i = this.questionClicked.indexOf(question)
                this.questionClicked.splice(i, 1)
            }
            else {

                this.questionClicked.push(question)

                this.$tracking.logEvent('Question Clicked', {'Route Name': this.$route.name, 'Question': question})
            }
        },
        answerToShow(question) {

            if (this.questionClicked.includes(question)) {

                return true
            }

            return false
        },
        parseMessage(message) {

            return message.replace('{emailLink}', `<a href="mailto:${this.getContactEmail()}">${this.getContactEmail()}</a>.`)
        }
    },
    computed: {

        displayMessages() {

            return typeof this.messages == 'object'
        }
    }
}
</script>
<template>
    <div class="card shadow my-5 d-none d-md-block" v-if="displayMessages">
        <div class="card-body row pb-0">
            <h5 class="mb-3">Toutes les réponses à vos questions !</h5>
            <div v-bind:key="index" v-for="(message, index) in messages" class="border-bottom message-hover">
                <div role="button" class="d-flex justify-content-between mt-2" @click="showAnswer(message.question)">
                   <p><strong><small><span v-html="message.question"></span></small></strong></p>
                    <h4> <span v-if="answerToShow(message.question)">-</span> 
                        <span v-else >+</span> 
                    </h4>
                </div>
                <p v-if="answerToShow(message.question)" > 
                    <small>
                        <span v-html="parseMessage(message.answer)">
                        </span>
                    </small>
                </p>
            </div>
        </div>
    </div>
</template>
<style>
    .message-hover:hover {

        background-color: rgb(243, 243, 243);
    }
</style>
