<script type="text/ecmascript-6">
import { CheckoutSdk } from '@mangopay/checkout-sdk';
import mangoService from '@/services/mango'

export default {

    props: {
        amount: Number,
        orderPublicId: String
    },
    mounted() {

        this.initMangoPaySDK()
    },
    data() {

        return {
            mangopaySdk: null,
            currency: 'EUR',
            mangoTermsAccepted: true
        }
    },
    methods: {
        async createCardRegistration(event) {

            this.$emit('createCardRegistration')
            if (!this.isProduction)
                console.log('createCardRegistration', event)

            try {

                let response = await mangoService.createCardAccount(this.currency, event, this.mangoTermsAccepted)
                let cardRegistrationInfos = response.data
                
                const newCardRegistrationInfos = {
                    'Id': cardRegistrationInfos.cardRegistrationId,
                    'Tag': cardRegistrationInfos.Tag,
                    'CreationDate': cardRegistrationInfos.CreationDate,
                    'UserId': cardRegistrationInfos.UserId,
                    'AccessKey': cardRegistrationInfos.accessKeyRef,
                    'PreregistrationData': cardRegistrationInfos.data,
                    'RegistrationData': cardRegistrationInfos.RegistrationData,
                    'CardId': cardRegistrationInfos.CardId,
                    'CardType': cardRegistrationInfos.CardType,
                    'CardRegistrationURL': cardRegistrationInfos.cardRegistrationURL,
                    'ResultCode': cardRegistrationInfos.ResultCode,
                    'ResultMessage': cardRegistrationInfos.ResultMessage,
                    'Currency': cardRegistrationInfos.Currency,
                    'Status': cardRegistrationInfos.Status
                }
                
                return newCardRegistrationInfos
            }
            catch (error) {

                this.$emit('error', error)
            }
        },
        async createPayment(event) {

            if (!this.isProduction)
                console.log('createPayment', event)

            this.$emit('createPayment')

            try {

                let depositCreatedResponse = await mangoService.createDepositPreAuth(this.orderPublicId, event.Currency, event.CardId, 2)
                let depositPreAuthData = depositCreatedResponse.data
            
                return depositPreAuthData
            }
            catch (error) {

                this.$emit('error', error)
            }
        },
        error(event) {

            if (!this.isProduction)
                console.log('error', event)

            this.$emit('error', event.detail.error.ResultMessage)
        },
        async paymentComplete(event) {

            if (!this.isProduction)
                console.log('paymentComplete', event)

            let status = event.detail.Status
            //TODO : call from server with webhook
            if (status == 'SUCCEEDED') {

                try {

                    let depositId = event.detail.Id
                    await mangoService.createOrderReservationFromDeposit(this.orderPublicId, depositId)
                }
                catch (error) {

                    this.$emit('error', error)
                }
            }

            this.$emit('paymentComplete', status)
        },
        async initMangoPaySDK() {

            if (!this.isProduction)
                console.log('initMangoPaySDK')

            const customLanguage = {

                'card.psp.legal.notice': 'Cette transaction est faite via le service de paiement Mangopay',
                'card.psp.legal.privacy': 'Informations liées à vos données personnelles',

                'card-information.header.label': 'Informations de carte bancaire',

                'card.number.placeholder': '1234 1234 1234 1234',
                'card.number.aria-label': 'Numéro de carte',
                'card.number.errors.required': 'Le numéro de carte est obligatoire',
                'card.number.errors.min-length': '13 chiffre minimum',
                'card.number.errors.invalid-number': 'Numéro de carte non valide',

                'card.save.label': 'Enregistrer la carte',
                'card.saved-cards.choose-card.label': 'Choisir une carte',
                'card.saved-cards.clear-card.label': 'Annuler',
                'card.saved-cards.saved-card.aria-label': 'La numéro de carte se finie par',

                'card.billing-name.placeholder': 'Nom du proprétaire de la carte',
                'card.billing-name.aria-label': 'Nom du proprétaire de la carte',
                'card.billing-name.errors.required': 'Le nom est obligatoire',
                'card.billing-name.errors.min-length': 'Le nom est trop court',

                'card.expiry.placeholder': 'MM / AA',
                'card.expiry.aria-label': 'Date d\'expiration',
                'card.expiry.errors.required': 'La date d\'expiration est obligatoire',
                'card.expiry.errors.pattern': 'La date d\'expiration n\'est pas valide (MM/AA)',
                'card.expiry.errors.expired': 'La carte n\'est plus valide',

                'card.cvc.placeholder': 'CVV',
                'card.cvc.aria-label': 'CVV',
                'card.cvc.errors.required': 'Le code de sécurité est obligatoire',
                'card.cvc.errors.min-length': 'Le code de sécurité est trop court',
                'card.cvc.errors.max-length': 'Le code de sécurité est trop long',

                'pay-button.label': 'Valider la pré-autorisation',
                'pay-button.aria-label': 'Valider la pré-autorisation avec la carten',

                'payment-success-state.label': 'Pré-autorisation réussie',

                'error.invalid_cvc': 'Invalid CVC',
                'error.authorization_required': 'Authorization Required',
                'error.contact_card_issuer': 'Contact Card Issuer',
                'error.unsupported_card': 'Unsupported Card',
                'error.insufficient_funds': 'Insufficient Funds',
                'error.unsupported_currency': 'Unsupported Currency',
                'error.card_rejected': 'Card Rejected',
                'error.expired_card': 'Expired Card',
                'error.suspected_fraud': 'Suspected Fraud',
                'error.general_decline': 'General Decline',
                'error.incorrect_number': 'Incorrect Number',
                'error.incorrect_pin': 'Incorrect Pin',
                'error.invalid_address': 'Invalid Address',
                'error.invalid_card_or_account': 'Invalid Card or Account',
                'error.invalid_amount': 'Invalid Amount',
                'error.invalid_date': 'Invalid Date',
                'error.lost_restricted_or_stolen_card': 'Lost, Restricted or Stolen Card',
                'error.blocked_list': 'Blocked List',
                'error.not_permitted': 'Not Permitted',
                'error.offline_or_online_pin_required': 'Offline or Online Pin Required',
                'error.pin_retry_exceeded': 'Pin Retry Exceeded',
                'error.processing_error': 'Processing Error',
                'error.withdrawal_count_limit_exceeded': 'Withdrawal Count Limit Exceeded',
                'error.unknown': 'Unknown error',
                'error.fraud': 'Fraud',
                'error.three_d_secure': '3DS Failed',
                'error.custom_rule': 'Custom Rule',

                'loading.label': 'En cours de traitement'
            };

            const options = {
                clientId: process.env.VUE_APP_MANGO_CLIENT_ID,
                profilingMerchantId: process.env.VUE_APP_MANGO_PROFILING_MERCHANT_ID,
                environment: this.isProduction ? 'PRODUCTION' : 'SANDBOX',
                locale: customLanguage,
                supportedCardBrands: ['VISA', 'MASTERCARD', 'CB'],
                amount: {
                    'currency': this.currency,
                    'value': this.amount
                },
                paymentMethods: [
                    {
                        type: 'card',
                        options: {
                            onCreateCardRegistration: this.createCardRegistration,
                            onCreatePayment: this.createPayment
                        }
                    }
                ]
            }

            try {

                this.mangopaySdk = await CheckoutSdk.loadCheckoutSdk('#container', options)
                if (!this.mangopaySdk) {
                    
                    throw new Error('Failed to load MangopayCheckoutSdk');
                }
            }
            catch (error) {

                console.log(this.mangopaySdk)

                if (!this.isProduction)
                    console.log(error)
            }

            this.mangopaySdk.on('paymentComplete', this.paymentComplete)
            this.mangopaySdk.on('error', this.error)
        }
    },
    computed: {

        isProduction() {

            return process.env.VUE_APP_ENVIRONMENT === 'production'
        }
    }
}
</script>
<template>
    <div id="checkoutForm" class="card">
        <div id="container" class="card-body"></div>
    </div>
</template>
