<script type="text/ecmascript-6">
export default {

    props: ['bonuses', 'merchantCss']
}
</script>
<template>
    <section>
        <div v-for="bonus in bonuses" :key="bonus.name" class="p-2" :class="'border-' + merchantCss + ' bg-white'" style="max-width:600px">
            <div class="d-flex">
                <img style="height:100px;width:100px" :src="bonus.img">
                <div>
                    <p>Pour l'achat {{ bonus.productName }}</p>
                    <p><strong>{{ bonus.amount }} € DE BONUS</strong> en plus de la valeur de reprise de votre {{ bonus.sellType }}</p>
                </div>
            </div>
            <p class="text-muted m-2"><small>Bonus valable jusqu'au {{ bonus.couponValidityDate }} {{ bonus.couponValidityPlace }}.</small></p>
        </div>
    </section>
</template>
