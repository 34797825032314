<script type="text/ecmascript-6">
export default {
    props: {
        'maxBestOffer': Object, 
        'merchant': Object,
        'isMax': {
            type: Boolean,
            default: true
        },
        'bonus': {
            type: Object,
            default: null
        },
        'maxBonus': Number,
        'maxBonusType': String,
        'paymentType': {
            'type': String
        }
    },
    methods: {
        getBrandName() {

            return this.bonus.name.toUpperCase()
        },
        getBonusAmount() {

            let value = this.bonus.bonus_amount / 100
            if (this.bonus.bonus_amount_type == 'percent') {

                return value.toString() + ' %'
            }
            
            return '+' + this.$options.filters.currency(value.toString())

        },
        getMaxBonusAmount() {

            let value = this.maxBonus / 100
            if (this.maxBonusType == 'percent') {

                return value.toString() + ' %'
            }
            
            return '+' + this.$options.filters.currency(value.toString())
        }
    },
    computed: {
        paymentTypeData() {

            if (this.paymentType != null) return this.paymentType

            if (this.merchant == undefined) return ''

            if (this.merchant.coupon_type != undefined && this.merchant.has_cash) {
            
                return 'both'
            }

            if (this.merchant.coupon_type != undefined) {
            
                return 'coupon'
            }
            
            if (this.merchant.has_cash) {

                return 'cash'
            }

            return ''
        },
        offerPrice() {

            if (this.paymentType == 'cash' && this.maxBestOffer.price_raw != undefined) return this.maxBestOffer.price_raw

            return this.maxBestOffer.price
        },
        bonusColor() {

            return this.bonus.color
        },
        offerColor() {

            if (this.paymentType == 'cash') return 'text-info'

            return 'text-success'
        },
    }
}
</script>
<template>
    <div v-if="bonus" class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-8" :class="'text-' + bonusColor">
                <strong>
                    <span v-if="isMax">Meilleure offre de reprise</span>
                    <span v-if="!isMax">Offre de reprise</span>
                </strong><br/>
            </div>
            <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                <h4 :class="'text-' + bonusColor" v-if="maxBestOffer">{{offerPrice | currency}}*</h4>
            </div>
        </div>
        <div v-if="this.merchant != undefined && this.maxBestOffer.price > 0" class="justify-content-end mt-3">
            <p v-if="paymentTypeData == 'coupon'" class="font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.coupon_platform_name}}
                valable {{merchant.coupon_validity_period}} 
                <span>pour l'achat de produits {{ bonus.brand.name }}</span> <span v-html="merchant.coupon_validity_places"></span>.
            </p>
            <p v-if="paymentTypeData == 'cash'" class="font-size-xxs">
                Sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis. Argent disponible sous 14 jours après validation.
            </p>
        </div>
        <hr class="card-separator"/>
        <div>
            <div class="row mt-4" :class="'text-' + bonusColor">
                <div class="col-8">
                    <span>
                        <strong>Bonus {{getBrandName()}}</strong><br/>
                    </span>
                </div>
                <div class="col-4 justify-content-end d-flex flex-wrap align-items-center">
                    <h4 :class="'text-' + bonusColor">{{getBonusAmount()}}*</h4>
                </div>
            </div>
            <div class="justify-content-end mt-2">
                <p class="text-gd-faint font-size-xxs">
                    <span v-if="!bonus.is_cash_refund">Sous la forme de code de réduction {{merchant.coupon_platform_name}}</span>
                    <span v-html="bonus.legal_application_label"></span>
                </p>
            </div>
        </div>
    </div>
    <div v-else class="card-body pb-0">
        <hr class="card-separator"/>
        <div class="row mt-4">
            <div class="col-6">
                <span v-if="isMax">Meilleure offre<br/>de reprise <small class="font-size-xxs"> <br>(pour un état comme neuf)</small></span>
                <span v-if="!isMax">Offre de reprise</span>
            </div>
            <div class="col-6 justify-content-end d-flex flex-wrap align-items-center">
                <h4 :class="offerColor" v-if="maxBestOffer">{{offerPrice | currency}}*</h4>
            </div>
        </div>
        <div v-if="maxBonus != undefined && maxBonus > 0" class="row mt-2">
            <div class="col-5">En ce moment&nbsp;:</div>
            <div class="col-7 text-right">jusqu'à <strong class="text-success">{{getMaxBonusAmount()}}</strong> de bonus<br/><small style="font-size:60%">(à choisir à l'étape suivante)</small></div>
        </div>
        <div v-if="this.merchant != undefined" class="justify-content-end mt-3">
            <p v-if="paymentTypeData == 'coupon' || paymentTypeData == 'both'" class="font-size-xxs">
                En {{merchant.coupon_name}} {{merchant.coupon_platform_name}} valable {{merchant.coupon_validity_period}} {{merchant.coupon_validity_products}} <span v-html="merchant.coupon_validity_places"></span>
            </p>
            <p class="font-size-xxs" v-if="paymentTypeData == 'both'">ou</p>
            <p v-if="paymentTypeData == 'cash' || paymentTypeData == 'both'" class="font-size-xxs">
                En cash, sous réserve de conformité de votre produit avec votre déclaratif. Une pièce d'identité et un IBAN valides devront être fournis.<br />Argent disponible sous 14 jours après validation.
            </p>
        </div>
    </div>
</template>
