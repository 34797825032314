import axios from 'axios'

export default {

    list(merchantId) {

        var path = '/api/customer/merchants/' + merchantId + '/categories'

        return axios.get(path)
    }
}
