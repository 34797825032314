<script type="text/ecmascript-6">

import mediaMixin from '@/mixins/media'

export default {
    mixins: [mediaMixin],
    props: ['productType'],
    methods: {

        getCO2Data() {

            switch(this.productType) {
                case 'CONSOLE':
                    return '69'
                case 'GRAPHIC_CARD':
                    return '9'
                case 'LAPTOP':
                    return '124'
                case 'SMARTPHONE_APPLE':
                    return '33'
                case 'SMARTPHONE_OTHER':
                    return '33'
                case 'TABLET_APPLE':
                    return '47'
                case 'TABLET_OTHER':
                    return '47'
                case 'OTHER':
                    return 'Des '
                default:
                    return 'Des '
            }
        },
        goToTrustPilot() {

            if (this.merchant == undefined) return ''

            if (this.merchant.white_label_domain != undefined) return ''

            window.open('https://fr.trustpilot.com/review/greendid.com', '_blank')
        },
        getReinsuranceImg(imgName) {

            if (this.merchantCss == 'bricomarche') {

                imgName = 'bricomarche-' + imgName
            }

            return this.getLocalImage(imgName)
        }
    }
}

</script>

<template>
    <div class="card shadow-lg mt-2 w-100">
        <div class="card-body pe-3">
            <div class="row">
                <div class="col-6">
                    <img width="20px" :src="getReinsuranceImg('send-check.png')"/><span class="ms-1 font-size-xxs"> Frais d'expédition <strong>offerts</strong></span>
                </div>
                <div v-if="merchant.coupon_name != '' && merchant.coupon_name != ' '" class="col-6">
                    <img :src="getReinsuranceImg('icon-bon-immediat.png')" width="20px" height="19px"/><span class="ms-1 font-size-xxs"> {{merchant.coupon_name}} <strong v-if="merchant.can_have_immediat_coupon">- immédiat</strong><sup v-if="merchant.can_have_immediat_coupon">(1)</sup></span>
                </div>
                <div v-else-if="merchant.has_cash" class="col-6">
                    <i class="bi bi-cash"/><span class="ms-1 font-size-xxs"> Reprise en <strong>cash</strong></span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6">
                    <img v-if="merchantCss == 'bricomarche'" width="20" :src="getLocalImage('bricomarche-protect.png')" />
                    <i v-else class="bi bi-shield-shaded" />
                    <span class="ms-1 font-size-xxs"> Reprise par un <strong>professionnel</strong></span>
                </div>
                <div v-if="getCO2Data() != ''" class="col-6">
                    <img :src="getReinsuranceImg('icons8-planete-terre.png')" width="20" height="19"/><span class="ms-1 font-size-xxs"> {{this.getCO2Data()}}kg de CO2 <strong>économisés</strong></span>
                </div>
            </div>
        </div>
        <div v-if="merchant.white_label_domain == undefined" @click="goToTrustPilot()" class="card_footer rounded-bottom d-flex align-items-center bg-gd-dark" style="height: 40px;cursor:pointer">
            <img class="offset-1" src="/assets/img/trustpilot-rate.png" width="100" height="20"/><strong class="offset-1 text-light font-size-xxs">avis clients <span class="text-success">4,5 / 5</span> sur Trustpilot</strong>
        </div>
    </div>
</template>

