import basketService from '@/services/basket'
import merchantMixin from '@/mixins/merchant'

export default {

    mixins: {merchantMixin},
    data() {

        return {
            isBasketLoaded: false
        }
    },
    created() {

        if (this.$route.params.id)
            this.loadBasket(this.$route.params.id)
    },
    computed: {

        basket() {

            return this.$store.getters.basket
        },
        currentStep() {

            return this.$store.getters.currentStep
        },
        basketId() {

            return this.$route.params.id
        }
    },
    methods: {
        setCurrentStep(val) {

            this.$store.commit('setCurrentStep', val)
        },
        async loadBasket(basketId) {

            try {
                
                let t1 = performance.now()
                let response = await basketService.getBasket(basketId)
                let t2 = performance.now() 
                let basket = response.data

                this.$tracking.logEvent('Performance', {'Function': 'getBasket', 'Time': Math.round((t2 - t1))})

                if (basket.order_public_id != null) {

                    if (basket.type == 'iframe') {

                        this.$router.push({name: 'validatedIframe', params:{id: basket.order_public_id}})
                    }
                    else {

                        this.$router.push({name: 'orderConfirm', params:{id: basket.order_public_id}})
                    }
                }
                else {
                    
                    this.$store.commit('setBasket', basket)
                    this.isBasketLoaded = true
                    await this.loadMerchant(basket.merchant_id)
                }
            }
            catch(error) {

                if (this.$route.path.includes('iframe')) {

                    this.$router.push({name: 'error'})
                }
                else {

                    this.$router.push({name: 'basketError404'})
                }
            }
        },
        getConditionLabel(val, productType) {

            return this.$t('BASKET_CONDITION_LABEL.' + productType + '.' + val)
        }
    }
}
